import React, { useState, useEffect, useRef } from 'react';
import { Box,TextField, Button, Container} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import Modal from '@mui/material/Modal';
import MKBox from 'components/MKBox'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MessageCitationList from 'components/MessageList/messageCitationList'
import TypingSpinner from 'components/TypingSpinner';



import PDFViewer from 'components/PDFViewer';



const theme = createTheme({
  palette: {
    primary: {
      main: '#0C80A4',
    },
    secondary: {
      main: '#C45911',
    },
    background: {
      default: '#eeeeee',
    },
    text: {
      primary: '#FFFFFF',
    },
  },
  multilineColor:{
    color:'red'
},
  typography: {
    // fontFamily: 'Berlin Sans FB',
    h3: {
      color: '#FFFFFF',
    },
    h5: {
      color: '#FFFFFF',
    },
    body1: {
      color: '#FFFFFF',
    },
    h6: {
      color: '#FFFFFF',
    },
    body2: {
      color: '#FFFFFF',
    },
  },
});



const serverBaseURL = process.env.REACT_APP_BACKEND_URL;

//const serverBaseURL = "http://localhost:8000"

function PolicyPage() {
  const [firstName, setFirstName] = useState(sessionStorage.getItem("given_name"))
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  const [data, setData] = useState(null);
  const [thread_id, setThread_id] = useState('');
  const [assistant_id, setAssistantId] = useState('');
  const [loading, setLoading]= useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [handle_loading_message, setHandleLoadingMessage] = useState(false)

  const [pdfUrl, setPdfUrl] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(true)
  const [coordinates, setCoordinates] = useState(null)
  const [startPage, setStartPage] = useState(null)
  
  

  useEffect(() => {
    // If the user is logged in and has completed the form
    if (isLoggedIn) {
      
      handleInitialCall();
    }
  });
  
 
  const toTitleCase=(str)=> {
    return str.split('_').map(word => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
  }).join(' ');
};
  


  const handleInitialCall = () => {
    setIsLoggedIn(false)
    setLoading(true)
    setAssistantId('florida')
    setFirstName(sessionStorage.getItem("given_name"),"User")
    handleInitialize("florida")
  };
  
  const handleInitialize = async (assistant_id)=>{



    
    try {
      const response = await fetch(`${serverBaseURL}/policy-api/get-policy-thread`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ firstName:`${firstName}`, assistant_id:`${assistant_id}` }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setLoading(false)
      
      const data = await response.json();
      setThread_id(data.thread_id)
      setHandleLoadingMessage(false)
      typeOut(data.message, assistant_id); 

    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  }
  const filterAndExtractSentences = (data, selectedFile) => {
    let sentences = [];

    const filteredItems = data.filter(item => item.file === selectedFile).map(item => ({
                    x0: item.x0,
                    y0: item.y0,
                    x1: item.x1,
                    y1: item.y1,
                    pageIndex: item.pageIndex
                }))
                .filter((item,index,self)=>
                index === self.findIndex((t) =>(
                  t.x0 === item.x0 && 
                  t.y0 === item.y0 && 
                  t.x1 === item.x1 && 
                  t.y1 === item.y1 && 
                  t.pageIndex === item.pageIndex
                )))

    let highestScorePage = null;
 
    if (filteredItems.length > 0) {
                  // Use reduce to find the item with the maximum score
                  const highestScoreItem = filteredItems.reduce((maxItem, currentItem) => {
                      return (currentItem.score > maxItem.score) ? currentItem : maxItem;
                  }, filteredItems[0]);
          
                  highestScorePage = highestScoreItem.pageIndex;
              }            
            
            // Concatenate the filtered sentences
    sentences = sentences.concat(filteredItems);
        
    
    return {
      sentences, 
      highestScorePage
    }
  }

  const handleSend = () => {
    setHandleLoadingMessage(true)
    if (input.trim() !== '') {
      const newMessage = { user: 'You', text: input, time: new Date().toLocaleTimeString() };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      const query = input
      setInput('');
      handleResponse(query)
    }
  };

  const handleResponse = async (query) => {
    if (query ===""){

      return
    }


    const controller = new AbortController();
    const timeoutId = setTimeout(() => controller.abort(), 120000); 
    try {
    
      const response = await fetch(`${serverBaseURL}/policy-api/continue-policy-thread`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ thread_id : thread_id, new_message: query, assistant_id: assistant_id }),
        signal: controller.signal,
      });
  
      clearTimeout(timeoutId);

      if (!response.ok) {
        throw new Error('Network response was not ok now');
      }
     
      const data = await response.json();
     
      setData(data)
      typeOut(data.message, assistant_id); 
    } catch (error) {
      if (error.name === 'AbortError') {
        console.error('Fetch request timed out');
        const text_returned = {};
        text_returned.message = "Request timed out. Please try again.";
        typeOut(text_returned, assistant_id)
      } else {
        console.error('There was a problem with the fetch operation:', error);
        const text_returned = {};
        text_returned.message = 'There was a problem with the fetch operation'
        typeOut(text_returned, assistant_id)
      }
    }
  }
  const handleOpen = async (citation) => {
    
    const filename = citation.file_name;
    try {
        // Initiate both fetch operations concurrently
        const pdfFetchPromise = fetch(`${serverBaseURL}/policy-api/get_citations`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({filename: filename, assistant_id: assistant_id})
        });


        // Wait for both promises to resolve
        const pdfResponse = await pdfFetchPromise;

        if (!pdfResponse.ok) {
            throw new Error('Failed to fetch PDF.');
        }
        
        citation = data["results"]

        let needed_citation = filterAndExtractSentences(citation, filename)
        
        setCoordinates(needed_citation.sentences)
        setStartPage(needed_citation.highestScorePage)
        const blob = await pdfResponse.blob();
        const pdfUrl = URL.createObjectURL(blob);
        setPdfUrl(pdfUrl);

        

         // Assume highlightsData is already in the correct format

        if (pdfUrl) {
          
          openModal()
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

  const openModal = () =>{
    setModalIsOpen(true)
  }
  const closeModal = () => {
    setModalIsOpen(false);
   
    
  };

  const typeOut = (text_returned,assistant_id) => {
    setHandleLoadingMessage(false)
    const assistant_title = toTitleCase(assistant_id)
    const text = text_returned.message
    const citations = text_returned.citations
    setMessages((prevMessages) => {
      const lastMessage = prevMessages[prevMessages.length - 1];

      if (lastMessage && lastMessage.user === `${assistant_title} Medicaid AI Assistant`) {
        const updatedMessage = {
          ...lastMessage,
          text: lastMessage.text + text + (/[.!?]$/.test(text) ? ' ' : '')  // Add a space only if the text ends with a punctuation mark
        };
        return [...prevMessages.slice(0, -1), updatedMessage];
      } else { 
      
        let user_message = `${assistant_title} Medicaid AI Assistant`
        const newMessage = {
          user: user_message,
          text: text + (/[.!?]$/.test(text) ? ' ' : ''),  // Add a space only if the text ends with a punctuation mark
          time: new Date().toLocaleTimeString(),
          citations: citations
        };
        
        return [...prevMessages, newMessage];
      }
    });

  };
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Cleanup on component unmount
  useEffect(() => { 
    scrollToBottom();
  }, [messages]);

  useEffect(() => { 
    scrollToBottom()
  },[handle_loading_message]);


  return (
    
    <ThemeProvider theme={theme}>
      {loading ? (
       
        <MKBox sx={{ display: 'flex',  justifyContent: 'center', paddingTop:{s: 5, md:5}, paddingBottom:{xs:5, md:14}, backgroundColor: theme.palette.background.default}}>
       <TypingSpinner message={`Loading ${toTitleCase(assistant_id)} Medicaid Policy. Please Wait`}/>
       </MKBox>
      ):(
      <>
     <Box sx={{display: 'flex', flexDirection: 'column', height: '90vh', background: "linear-gradient(to right,#003366, #00AACC, #003366)", color: '#fff'}}> 
     <Modal
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ 'display':'flex',
          'justifyContent': 'center',
          'alignItems': 'center',
          'position':' fixed',
          'top': 0,
          'left': 0,
         'width': '100%',
          'height': '100%',
          'background': 'rgba(10, 0, 0, 0.5)',/* Semi-transparent background */
          'z-index': 1000 /* Ensure it's on top */}}
      >
        <MKBox  >
          <Container >
       <PDFViewer pdfUrl={pdfUrl} highlight_coordinates={coordinates} start_page={startPage}/>
        </Container>
        </MKBox>
      </Modal>
    <MessageCitationList messages={messages} handleOpen={handleOpen}  loading={handle_loading_message} />
    <Box sx={{ display: 'flex',  flexDirection: { xs: 'column', md: 'row' },padding: 1,paddingBottom: { xs: 2, md: 5 }, backgroundColor: "theme.palette.background.default" }}>
      <TextField
        variant="outlined"
        placeholder="Type a message"
        fullWidth
        multiline
        inputProps={{ style: { color: "#ffffff" } }}
        minRows={2}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={(e) => { if (e.key === 'Enter' && !e.shiftKey) { handleSend(); e.preventDefault(); } }}
        sx={{
          
          '& .MuiInputBase-root': {
            color: '#fff',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#fff',
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#fff',
            },
          },
          paddingLeft: { xs: 2, md: 20 },
          paddingRight: { xs: 2, md: 10 },
          fontSize: { xs: '0.9rem', md: '1rem' },
       
        }}
      />
      <Button variant="contained" color="primary" onClick={handleSend}   sx={{
    marginLeft: { xs: 0, md: 1 },
    marginTop: { xs: 1, md: 0 },
    backgroundColor: '#00608d',
    '&:hover': {
      backgroundColor: '#00608d',
    },
    width: { xs: '100%', md: 'auto' },

  }}>
        <SendIcon />
      </Button>
      </Box>
    </Box>
    </>)}

  </ThemeProvider>

)}

export default PolicyPage;
