/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect } from "react";

// react-router components
import { Routes, Route, Navigate, useLocation } from "react-router-dom";

// @mui material components
import { ThemeProvider } from "@mui/material/styles";
import CssBaseline from "@mui/material/CssBaseline";
import { Worker } from '@react-pdf-viewer/core';


// Material Kit 2 PRO React themes
import theme from "assets/theme";
import Presentation from "pages/presentation";

// Material Kit 2 PRO React routes
//import routes from "routes";
import { useAuth } from 'pages/authentication/AuthContext';

import SignInCover from "pages/authentication/SignIn/Cover";
import SignUpCover from "pages/authentication/SignUp/Cover";
import Bio from "pages/bio";

import KnowliProjects from "pages/knowliProject";

import LandingPage from "pages/landing/LandingPage";

import PageContainer from "examples/ExteriorDrawer/Drawer";
import KeywordVectorSearchPage from "pages/keywordVectorSearch/KeywordVectorSearch";

import PolicyPage from "pages/medicaidPolicy/MedicaidBot";
import ContractPage from "pages/contract/ContractBot";






function App() {
  const { pathname } = useLocation();
  const { user } = useAuth()



  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);



  
  // useEffect(() => {
  //   // Session Timeout Logout
  //   const INACTIVITY_TIMEOUT = 15 * 60 * 1000; // 15 minutes in milliseconds

  //   let timeoutId;

  //   const resetTimeout = () => {
  //     clearTimeout(timeoutId);
  //     timeoutId = setTimeout(() => {
  //       // Perform your logout logic here
  //       navigate("/");
  //     }, INACTIVITY_TIMEOUT);
  //   };

  //   const handleBeforeUnload = (event) => {
  //     // Perform your logout logic here
  //     // navigate("/"); // This will not work properly during unload
  //   };

  //   document.addEventListener('mousemove', resetTimeout);
  //   document.addEventListener('keydown', resetTimeout);

  //   resetTimeout();

  //   // Window/Tab Close Logout
  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   // Cleanup function to remove event listeners when component unmounts
  //   return () => {
  //     document.removeEventListener('mousemove', resetTimeout);
  //     document.removeEventListener('keydown', resetTimeout);
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // }, [navigate]);
  




  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
     

      <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.7.107/build/pdf.worker.min.js"/>

      <Routes>
        <Route path="/presentation" element={<Presentation />} />
        <Route path="/" element={<Navigate to="/presentation" />} />
        <Route path="/pages/authentication/sign_in" element={<SignInCover/>}/>
        <Route path="/pages/authentication/sign_up" element={<SignUpCover/>}/>
        <Route path="/pages/bio" element={<Bio />}></Route>
        <Route path="/pages/knowli-dashboards" element={<KnowliProjects/>}/>
        <Route path="/pages/projects" element={<PageContainer />}>
        {user ? (
          <>
          <Route index element={<LandingPage />} />
          <Route path="landing-page" element={<LandingPage />} />
          <Route path="knowli-dashboards" element={<KnowliProjects />} />
          <Route path="policy-chat" element={<PolicyPage />} />
          <Route path="policy-search" element={<KeywordVectorSearchPage />} />
          <Route path="contract-analysis" element={<ContractPage/>}/>
         
          </>
        ):(
          <>
          <Route index element={<SignInCover />} />
          <Route path="fraud-detection" element={<SignInCover/>} />
          <Route path="knowli-dashboards" element={<SignInCover />} />
          <Route path="policy-search" element={<SignInCover />} />
          <Route path="patient-check-in" element={<SignInCover />} />
          <Route path= "policy-chat" element={<SignInCover/>} />
          <Route path="contract-analysis" element={<SignInCover />} />
          <Route path="ontology-generator" element={<SignInCover/>}/>
          </>

        )}
        </Route>
        {user ? (
          <>
        <Route path="/pages/projects" element={<PageContainer />}>
          <Route index element={<LandingPage />} />
          <Route path="/pages/projects/knowli-dashboards" element={<KnowliProjects  />} />
          <Route path="/pages/projects/policy-chat" element={<PolicyPage />} />
          <Route path="/pages/projects/policy-search" element={<KeywordVectorSearchPage />} />
          <Route path="/pages/projects/contract-analysis" element={<ContractPage/>}/>
          <Route path="*" element={<Navigate to="/presentation" />} />
        </Route>
        </>
        ):(
          <></>

        )}

      </Routes>
    </ThemeProvider>
  );
}

export default App;