import React, {useEffect, useState} from 'react';
import Slider from 'react-slick';
import { Typography,  Paper, Card, CardContent, CardMedia, CardActions,
 AppBar, 
  Toolbar, 
  Button, 
  Box,
  CardActionArea,
  IconButton, 
  Drawer, 
  ListItem, 
  ListItemText,
  ListItemButton,
  ListItemIcon 
} from '@mui/material';
import {Link} from "react-router-dom";
import Icon from '@mdi/react';
import MenuIcon from '@mui/icons-material/Menu';
import {  mdiAccountGroup,mdiLaptopAccount,mdiMonitorDashboard,} from '@mdi/js';
import MKBox from 'components/MKBox';
import Container from "@mui/material/Container";
import styled from '@mui/material/styles/styled';
import MKTypography from "components/MKTypography";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import 'pages/knowliProject/knowliProject.css'
import slide1 from 'assets/images/FL_Medicaid_Enrollment.png'
import slide2 from 'assets/images/PotentiallyPreventable.png'
import slide3 from 'assets/images/BirthOutcomes.png'
import slide4 from 'assets/images/BakerAct.png'
import slide5 from 'assets/images/StateOpioid.png'




// Styled components for aesthetics
const StyledPaper = styled(Paper)(({ theme }) => ({
  background: 'linear-gradient(to right,#003366, #00AACC, #003366)', // Gradient background
  color: 'white',
  padding: theme.spacing(2),
  borderRadius: 0, // Removes border radius to cover entire viewport edge-to-edge
  boxShadow: 'none', // Optional: remove shadow if you want a flat design across the page
  overflow: 'hidden',
  textAlign: 'center',
  fontFamily: 'Lato, sans-serif', 
  position: 'absolute', // Ensures it can cover all content
  top: 0,
  left: 0,
  width: '100vw', // Viewport width
  height: '100vh', // Viewport height
  zIndex: -1, 
  overflowY: 'auto', // Enable vertical scrolling
  // Additional styles for scrollbars, if needed:
  '&::-webkit-scrollbar': {
    width: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: "#aaa",
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'white',
  }
}));

const KnowliProjects = () => {
    // Settings for the slick slider
    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };
  
    const StyledIcon = styled(Icon)({
      color: '#005a87',
    });
    const drawer = (
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={handleDrawerToggle}
        onKeyDown={handleDrawerToggle}
      >
  
      <ListItem key="Projects" disablePadding sx={{ display: 'block'}}>
            <Card
            sx={{
              fontSize: "5px",
              margin: '10px 0',
              boxShadow: 3,
              borderRadius: 2,
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
          > <CardActionArea component={Link} to="/pages/projects">
              <ListItemButton
                sx={{
                  minHeight: 48,
                 
                  // justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr:  3 ,
                    justifyContent: 'center',
                  }}
                >
                 <StyledIcon path={mdiLaptopAccount} size={1} />
                </ListItemIcon>
                <ListItemText primary="Projects" primaryTypographyProps={{ fontSize: '18px' }} />
              </ListItemButton>
              </CardActionArea>
              </Card>
      </ListItem>
      <ListItem key="Dashboards" disablePadding sx={{ display: 'block'}}>
            <Card
            sx={{
              fontSize: "5px",
              margin: '10px 0',
              boxShadow: 3,
              borderRadius: 2,
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
          > <CardActionArea component={Link} to="/pages/knowli-dashboards">
              <ListItemButton
                sx={{
                  minHeight: 48,
                 
                  // justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 3,
                    // mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                 <StyledIcon path={mdiMonitorDashboard} size={1} />
                </ListItemIcon>
                <ListItemText primary="Dashboards" primaryTypographyProps={{ fontSize: '18px' }} />
              </ListItemButton>
              </CardActionArea>
              </Card>
      </ListItem>
      <ListItem key="Our Team" disablePadding sx={{ display: 'block'}}>
            <Card
            sx={{
              fontSize: "5px",
              margin: '10px 0',
              boxShadow: 3,
              borderRadius: 2,
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
          > <CardActionArea component={Link} to="/pages/bio">
              <ListItemButton
                sx={{
                  minHeight: 48,
                 
                  // justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 3,
                    justifyContent: 'center',
                  }}
                >
                 <StyledIcon path={ mdiAccountGroup} size={1} />
                </ListItemIcon>
                <ListItemText primary="Our Team" primaryTypographyProps={{ fontSize: '18px' }} />
              </ListItemButton>
              </CardActionArea>
              </Card>
      </ListItem>
      </Box>
    );


    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0',
        focusOnSelect: true,
        variableWidth: true,
        beforeChange: (current, next) => handleBeforeChange(current, next),
        responsive: [{
                breakpoint: 768,
                settings: {
                    slidesToShow: 1
                }
            }]
    };

    useEffect(() => {
        // Set the initialSlide to be the center item
        const slides = document.querySelectorAll('.slick-slide');
        slides.forEach(slide => {
                if (slide.dataset.index === "0") {
                  slide.style.transform = 'scale(1)';
                } else {
                  slide.style.transform = 'scale(.8)';
                }
              });
        
    }, []);



    const handleBeforeChange = (current, next) => {
        const slides = document.querySelectorAll('.slick-slide');
        slides.forEach(slide => {
          if (slide.dataset.index === next.toString()) {
            slide.style.transform = 'scale(1)';
          } else {
            slide.style.transform = 'scale(.8)';
          }
        });
      };

    return (
    
        <StyledPaper>
         <MKBox component="nav" position="absolute" top="0.5rem" width="100%">
          {/* <MKBox variant="gradient" bgColor="dark" shadwo="sm" py={0.25}> */}

          <AppBar position="fixed" sx={{ zIndex: 1000 }}>
                    <Toolbar style={{ justifyContent: 'space-between',marginLeft:"2rem",marginRight:"2rem" }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {/* <img src={smImage} alt="Logo" style={{ height: '70px', marginRight: '1rem' }} /> */}
                            <MKTypography variant="h6"  component={Link} to="/presentation" color="white" >
                            Knowli-AI
                            </MKTypography>
                        </Box>
                        <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center', flexGrow: 1 }}>
                        <Button  style={{color:'white'}}component={Link} to="/pages/projects">
                        Projects
                        </Button>

                        <Button style={{color:'white'}} component={Link} to="/pages/knowli-dashboards">
                        Dashboards
                        </Button>
                        <Button style={{color:'white'}} component={Link} to="/pages/bio" >
                        Our Team
                        </Button>

                        </Box>
                        <Button style={{color:'white'}} component={Link} to="/pages/authentication/sign_in">
                        Sign In
                        </Button>
                        <Box sx={{ display: { xs: 'block', md: 'none' },float:"right"  }}>
                          <IconButton
                            color="white"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{float:"right", color:"white"}}
                          >
                            <MenuIcon />
                          </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer
                  anchor="left"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  ModalProps={{ keepMounted: true }} // Better open performance on mobile
                >
                  {drawer}
                </Drawer>
        </MKBox>
             <MKBox component="section" py={20} px={1}>
             <Container>
            <Typography variant="h2" gutterBottom sx={{ textShadow: "2px 2px 4px #222" ,color:"#fff", fontFamily:'Avenir, sans-serif', fontWeight:700}}>
                Empowering Florida's Public Health with Data-Driven Solutions from Knowli Data Science
            </Typography>
            <Typography variant="subtitle1" gutterBottom sx={{ textShadow: "2px 2px 4px #000" ,color:"#fff" ,fontFamily:"Avenir, sans-serif", fontWeight:400}}>
            Knowli Data Science proudly supports public health agencies in their mission to improve health outcomes and decision-making through the power of data visualization. Our intuitive dashboards deliver critical insights that inform policy decisions and optimize resource distribution. We make complex data accessible and actionable, enabling state agencies to deliver timely and targeted interventions that truly meet community needs.
            </Typography>
            <Slider {...settings} >
                <Card sx={{ maxWidth: 300 }}>
                        <CardMedia
                                sx={{ height: 150 }}
                                image={slide3}
                                title="Birth Outcomes Dashboard"
                                alt="Birth Outcomes Dashboard"
                        />
                        <CardContent>
                                <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:"bold", transform:"scale(1.5"}}>
                                Birth Outcomes
                                </Typography>
                                <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:"bold", transform:"scale(1.5"}}>
                                Dashboard
                                </Typography>
                                <Typography gutterBottom variant="h6" component="div">
                                Florida Agency for Health Care Administration
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{fontSize:"12px"}}>
                                Tracks critical maternal and infant health indicators, such as cesarean deliveries 
                                and preterm births, to support healthcare strategies aimed at improving prenatal and postnatal care outcomes.
                                </Typography>
                        </CardContent>
                        <CardActions>
                                <Button size="small" href=" https://ahca.myflorida.com/medicaid/medicaid-policy-quality-and-operations/medicaid-policy-and-quality/medicaid-quality/quality-initiatives" target="_blank" rel="noopener noreferrer">Learn more</Button>
                                <Button size="small" href="https://bi.ahca.myflorida.com/t/FLMedicaid/views/BirthOutcomesDashboardSeries-External/CS-AgeRaceDashboard?%3Adisplay_count=n&%3Aembed=y&%3AisGuestRedirectFromVizportal=y&%3Aorigin=viz_share_link&%3AshowAppBanner=false&%3AshowVizHome=n" target="_blank" rel="noopener noreferrer">Dashboard link</Button>
                        </CardActions>
                </Card>
                <Card sx={{ maxWidth: 300 }}>
                        <CardMedia
                                sx={{ height: 150 }}
                                image={slide4}
                                title="Baker Act Dashboard"
                                alt="Baker Act Dashboard"
                        />
                        <CardContent>
                                <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:"bold"}}>
                                Baker Act 
                                </Typography>
                                <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:"bold"}}>
                                Dashboard
                                </Typography>
                                <Typography gutterBottom variant="h6" component="div">
                                Florida Department of Children and Families
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{fontSize:"12px"}}>
                                Delivers detailed demographic and geographic insights into involuntary mental health holds in Florida, 
                                assisting in the analysis of crisis patterns and the enhancement of mental health interventions.
                                </Typography>
                        </CardContent>
                        <CardActions>
                        <Button size="small" href="https://www.myflfamilies.com/BakerActDashboard" target="_blank" rel="noopener noreferrer">Learn more</Button>
                        <Button size="small" href="https://www.myflfamilies.com/BakerActDashboard" target="_blank" rel="noopener noreferrer">Dashboard link</Button>
                        </CardActions>
                </Card>
                <Card sx={{ maxWidth: 300 }}>
                        <CardMedia
                                sx={{ height: 150 }}
                                image={slide5}
                                title="State Opioid Dashboard"
                                alt="State Opioid Dashboard"
                        />
                        <CardContent>
                                <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:"bold"}}>
                                State Opioid
                                </Typography>
                                <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:"bold"}}>
                                Dashboard
                                </Typography>
                                <Typography gutterBottom variant="h6" component="div">
                                Florida Department of Children and Families
                                </Typography>
                                <Typography variant="body2" color="text.secondary" sx={{fontSize:"12px"}}>
                                Analyzes opioid-related data, including overdose rates and hospital visits, 
                                with a predictive county vulnerability score to inform strategic planning and resource allocation in combating opioid abuse.
                                </Typography>
                        </CardContent>
                        <CardActions>
                        <Button size="small" href="https://floridaopioidsettlement.com/resources/" target="_blank" rel="noopener noreferrer">Learn more</Button>
                        <Button size="small" href="https://app.powerbigov.us/view?r=eyJrIjoiMjhkNzRmZWEtMDljZS00ZjMzLWExODctOGJmMDU3MjM4ODI0IiwidCI6ImY3MGRiYTQ4LWIyODMtNGM1Ny04ODMxLWNiNDExNDQ1YTk0YyJ9" target="_blank" rel="noopener noreferrer">Dashboard link</Button>
                        </CardActions>
                </Card>
                <Card sx={{ maxWidth: 300 }}>
                        <CardMedia
                                sx={{ height: 150 }}
                                image={slide1}
                                title="Florida Medicaid Enrollment Dashboard"
                                alt="Florida Medicaid Enrollment Dashboard"
                        />
                        <CardContent>
                                <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:"bold"}}>
                                Florida Medicaid Enrollment Dashboard
                                </Typography>
                                <Typography gutterBottom variant="h6" component="div">
                                Florida Agency for Health Care Administration
                                </Typography>
                                <Typography variant="body2" color="text.secondary"  sx={{fontSize:"12px"}}>
                                Provides comprehensive demographic insights and enrollment trends for Florida's Medicaid, 
                                enabling policymakers to effectively allocate resources and understand regional healthcare dynamics.
                                </Typography>
                        </CardContent>
                        <CardActions>
                        <Button size="small" href="https://ahca.myflorida.com/medicaid/medicaid-finance-and-analytics/medicaid-data-analytics/medicaid-data-visualization-series" target="_blank" rel="noopener noreferrer">Learn more</Button>
                        <Button size="small" href="https://bi.ahca.myflorida.com/t/FLMedicaid/views/MedicaidEnrollmentDashboardExternal/EnrollmentFastFacts?%3Adisplay_count=n&%3Aembed=y&%3AisGuestRedirectFromVizportal=y&%3Aorigin=viz_share_link&%3AshowAppBanner=false&%3AshowVizHome=n" target="_blank" rel="noopener noreferrer">Dashboard link</Button>
                        </CardActions>
                </Card>
                <Card sx={{ maxWidth: 300 }}>
                        <CardMedia
                                sx={{ height: 150 }}
                                image={slide2}
                                title="Potentially Preventable Healthcare Events Dashboard"
                                alt="Preventable Healthcare Events Dashboard"
                        />
                        <CardContent>
                                <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:"bold"}}>
                                Potentially Preventable Healthcare Events 
                                </Typography>
                                <Typography gutterBottom variant="h6" component="div">
                                Florida Agency for Health Care Administration
                                </Typography>
                                <Typography variant="body2" color="text.secondary"  sx={{fontSize:"12px"}}>
                                Highlights potentially preventable medical visits, readmissions, and admissions, aiding in the 
                                identification of inefficiencies and the improvement of patient management and healthcare system cost-efficiency.
                                </Typography>
                        </CardContent>
                        <CardActions>
                        <Button size="small" href="https://ahca.myflorida.com/medicaid/medicaid-policy-quality-and-operations/medicaid-policy-and-quality/medicaid-quality/quality-initiatives" target="_blank" rel="noopener noreferrer">Learn more</Button>
                        <Button size="small" href="https://bi.ahca.myflorida.com/t/FLMedicaid/views/PPEDashboard01_08_24/PPVsbyHealthPlan?%3Aembed=y&%3AisGuestRedirectFromVizportal=y" target="_blank" rel="noopener noreferrer">Dashboard link</Button>
                        </CardActions>
                </Card>
            </Slider>
            </Container>
            </MKBox>
            </StyledPaper>
  
    );
};

export default KnowliProjects;
