import {useState } from "react";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

import { AppBar, 
  Toolbar, 
  Button, 
  Box,
  Card,
  CardActionArea,
  IconButton, 
  Drawer, 
  ListItem, 
  ListItemText,
  ListItemButton,
  ListItemIcon } from '@mui/material';
import {Link} from "react-router-dom";
import styled from '@mui/material/styles/styled';
import Icon from '@mdi/react';
import MenuIcon from '@mui/icons-material/Menu';
import {  mdiAccountGroup,mdiLaptopAccount,mdiMonitorDashboard,} from '@mdi/js';



// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKBadge from "components/MKBadge";

import MKTypography from "components/MKTypography";

// my_components

// import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import SimpleBlogCard from "examples/Cards/BlogCards/SimpleBlogCard"
import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard"

// Images

import bgImage2 from "assets/images/office-dark.jpg";

import bgPattern from "assets/images/shapes/pattern-lines.svg"


import briann_booth from "assets/images/KnowliTeamPhotos/BriannBooth.jpg"
import curtis_warren from "assets/images/KnowliTeamPhotos/CurtisWarren.jpg"
import kyle_saunders from "assets/images/KnowliTeamPhotos/KyleSaunders.jpg"
import matt_russo from "assets/images/KnowliTeamPhotos/MattRusso.jpg"
import rishita_gupta from "assets/images/KnowliTeamPhotos/RishitaGupta.jpg"
import robert_johnson from "assets/images/KnowliTeamPhotos/RobertJohnson.jpg"
import sadaf_martin from "assets/images/KnowliTeamPhotos/SadafMartin.jpg"
import sachin_murthy from "assets/images/KnowliTeamPhotos/SachinMurthy.jpeg"
import tim_arthur from "assets/images/KnowliTeamPhotos/TimArthur.jpg"
import tyler_bruefach from "assets/images/KnowliTeamPhotos/TylerBruefach.jpg"
import amy_thorn from "assets/images/CSuits_Knowli_TeamPhotos/AmyThorn.jpg"
import andrew_linn from "assets/images/CSuits_Knowli_TeamPhotos/AndrewLinn.jpg"
import belal_ghaffari from "assets/images/CSuits_Knowli_TeamPhotos/BelalGhaffari.jpg"
import danielle_sharp from "assets/images/CSuits_Knowli_TeamPhotos/DanielleSharp.jpg"
import emily_saras from "assets/images/CSuits_Knowli_TeamPhotos/EmilySaras.jpg"
import john_kane from "assets/images/CSuits_Knowli_TeamPhotos/JohnKane.jpg"
import renee_kane from "assets/images/CSuits_Knowli_TeamPhotos/final_renee.jpg"
import kurt_van_etten from "assets/images/KnowliTeamPhotos/kurt.png"
import marten_scheffers from "assets/images/KnowliTeamPhotos/martin.jpg"
import alan_staney from "assets/images/KnowliTeamPhotos/alan.jpg"
import evan_barksdale from "assets/images/KnowliTeamPhotos/EvanBarksdale.jpeg"
import heedeok_cho from "assets/images/KnowliTeamPhotos/cho_photo.jpg"


// Routes
// import routes from "routes";

const Bio = ()=> {

  const [mobileOpen, setMobileOpen] = useState(false);

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const StyledIcon = styled(Icon)({
    color: '#005a87',
  });
  const drawer = (
    <Box
      sx={{ width: 250 }}
      role="presentation"
      onClick={handleDrawerToggle}
      onKeyDown={handleDrawerToggle}
    >

    <ListItem key="Projects" disablePadding sx={{ display: 'block'}}>
          <Card
          sx={{
            fontSize: "5px",
            margin: '10px 0',
            boxShadow: 3,
            borderRadius: 2,
            transition: 'transform 0.3s ease-in-out',
            '&:hover': {
              transform: 'scale(1.05)',
            },
          }}
        > <CardActionArea component={Link} to="/pages/projects">
            <ListItemButton
              sx={{
                minHeight: 48,
               
                // justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr:  3 ,
                  justifyContent: 'center',
                }}
              >
               <StyledIcon path={mdiLaptopAccount} size={1} />
              </ListItemIcon>
              <ListItemText primary="Projects" primaryTypographyProps={{ fontSize: '18px' }} />
            </ListItemButton>
            </CardActionArea>
            </Card>
    </ListItem>
    <ListItem key="Dashboards" disablePadding sx={{ display: 'block'}}>
          <Card
          sx={{
            fontSize: "5px",
            margin: '10px 0',
            boxShadow: 3,
            borderRadius: 2,
            transition: 'transform 0.3s ease-in-out',
            '&:hover': {
              transform: 'scale(1.05)',
            },
          }}
        > <CardActionArea component={Link} to="/pages/knowli-dashboards">
            <ListItemButton
              sx={{
                minHeight: 48,
               
                // justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 3,
                  // mr: open ? 3 : 'auto',
                  justifyContent: 'center',
                }}
              >
               <StyledIcon path={mdiMonitorDashboard} size={1} />
              </ListItemIcon>
              <ListItemText primary="Dashboards" primaryTypographyProps={{ fontSize: '18px' }} />
            </ListItemButton>
            </CardActionArea>
            </Card>
    </ListItem>
    <ListItem key="Our Team" disablePadding sx={{ display: 'block'}}>
          <Card
          sx={{
            fontSize: "5px",
            margin: '10px 0',
            boxShadow: 3,
            borderRadius: 2,
            transition: 'transform 0.3s ease-in-out',
            '&:hover': {
              transform: 'scale(1.05)',
            },
          }}
        > <CardActionArea component={Link} to="/pages/bio">
            <ListItemButton
              sx={{
                minHeight: 48,
               
                // justifyContent: open ? 'initial' : 'center',
                px: 2.5,
              }}
            >
              <ListItemIcon
                sx={{
                  minWidth: 0,
                  mr: 3,
                  justifyContent: 'center',
                }}
              >
               <StyledIcon path={ mdiAccountGroup} size={1} />
              </ListItemIcon>
              <ListItemText primary="Our Team" primaryTypographyProps={{ fontSize: '18px' }} />
            </ListItemButton>
            </CardActionArea>
            </Card>
    </ListItem>
    </Box>
  );
  return (
    <>
      <MKBox component="header" position="relative">
        <MKBox component="nav" position="absolute" top="0.5rem" width="100%">
          {/* <MKBox variant="gradient" bgColor="dark" shadwo="sm" py={0.25}> */}

          <AppBar position="fixed" sx={{ zIndex: 1000 }}>
                    <Toolbar style={{ justifyContent: 'space-between',marginLeft:"2rem",marginRight:"2rem" }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            {/* <img src={smImage} alt="Logo" style={{ height: '70px', marginRight: '1rem' }} /> */}
                            <MKTypography variant="h6"  component={Link} to="/presentation" color="white" >
                            Knowli-AI
                            </MKTypography>
                        </Box>
                        <Box sx={{ display: { xs: 'none', md: 'flex' }, justifyContent: 'center', flexGrow: 1 }}>
                        <Button  style={{color:'white'}}component={Link} to="/pages/projects/policy-chat">
                        Policy Chat
                        </Button>

                        <Button style={{color:'white'}} component={Link} to="/pages/knowli-dashboards">
                        Dashboards
                        </Button>
                        <Button style={{color:'white'}} component={Link} to="/pages/bio" >
                        Our Team
                        </Button>

                        </Box>
                        <Button style={{color:'white'}} component={Link} to="/pages/authentication/sign_in">
                        Sign In
                        </Button>
                        <Box sx={{ display: { xs: 'block', md: 'none' },float:"right"  }}>
                          <IconButton
                            color="white"
                            aria-label="open drawer"
                            edge="start"
                            onClick={handleDrawerToggle}
                            sx={{float:"right", color:"white"}}
                          >
                            <MenuIcon />
                          </IconButton>
                        </Box>
                    </Toolbar>
                </AppBar>
                <Drawer
                  anchor="left"
                  open={mobileOpen}
                  onClose={handleDrawerToggle}
                  ModalProps={{ keepMounted: true }} // Better open performance on mobile
                >
                  {drawer}
                </Drawer>
        </MKBox>
        <MKBox
        minHeight="35vh"
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.5),
              rgba(gradients.dark.state, 0.5)
            )}, url(${bgImage2})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >

</MKBox>
        <MKBox
          component="section"
          variant="gradient"
          bgColor="light"
          position="relative"
          py={1}
          px={{ xs: 2, lg: 0 }}
          mx={-2}
          my={-5}
        >
          <Container sx={{marginTop:10}}>
            <Grid >
              <Grid item xs={12} md={8} sx={{ mb: 6}}>
                <MKTypography variant="h3" color="dark" sx={{marginBottom:2}}>
                  A Great Addition to Any Team or Company
                </MKTypography>
                <MKTypography variant="body2" color="dark" opacity={0.8}>
                Unlock transformative insights with Knowli Data Science. Our experts don’t just analyze data—they reinvent how you see your challenges and unlock new opportunities. With decades of expertise spanning data science, clinical applications, and in-depth research, we deliver the 'Knowli Difference'—transforming complex data into actionable strategies that drive success for public and private organizations.              </MKTypography>
              </Grid>
            </Grid>
       
            <Grid container spacing={6} justifyContent="center" sx={{ mt: 0, mb: 6 }}>
            <Grid item xs={12} sm={8} md={4.5} sx={{ mt: 0, mb: 6 }}>
                <MKBox mb={1}>
              <SimpleBlogCard 
                image={renee_kane}
                name="Renee Kane, MPH, RD, LD/N"
                title="President & Co-Founder"
                // description="Andrew Linn is the Chief Innovation Officer at Knowli Data Science. Leveraging a rich background in analytics and digital forensics, Andrew works with dedicated teams of data scientists and subject matter experts to guide the company into the future of AI and machine learning technologies. With his leadership, Knowli is harnessing collective expertise to innovate and deliver dynamic solutions to redefine industry standards and enhance client outcomes."
                // action= {{
                //   type: "internal",
                //   // route: "/sections/page-sections/general-cards",
                //   // color: "info",
                //   // label: "More about me",
                // }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} sm={8} md={4.5} sx={{ mt: 0, mb: 6 }}>
            <MKBox mb={1}>
              <SimpleBlogCard
                image={john_kane}
                name="John C. Kane, MBA, RRT-NPS"
                title="COB & Co-Founder"
                // description="Andrew Linn is the Chief Innovation Officer at Knowli Data Science. Leveraging a rich background in analytics and digital forensics, Andrew works with dedicated teams of data scientists and subject matter experts to guide the company into the future of AI and machine learning technologies. With his leadership, Knowli is harnessing collective expertise to innovate and deliver dynamic solutions to redefine industry standards and enhance client outcomes."
                // action= {{
                //   type: "internal",
                //   // route: "/sections/page-sections/general-cards",
                //   // color: "info",
                //   // label: "More about me",
                // }}
              />
            </MKBox>
          </Grid>
        </Grid>
        <Grid container spacing={4}  sx={{ mt: 0, mb: 6 }}>
        <Grid item xs={12} sm={8} md={4} sx={{ mt: 0, mb: 6 }}>
                <MKBox mb={1}>
                  <SimpleBlogCard
                    image={emily_saras}
                    name="Emily Saras, PhD"
                    title="CEO & Data Scientist"
                    // description="Andrew Linn is the Chief Innovation Officer at Knowli Data Science. Leveraging a rich background in analytics and digital forensics, Andrew works with dedicated teams of data scientists and subject matter experts to guide the company into the future of AI and machine learning technologies. With his leadership, Knowli is harnessing collective expertise to innovate and deliver dynamic solutions to redefine industry standards and enhance client outcomes."
                    // action= {{
                    //   type: "internal",
                    //   // route: "/sections/page-sections/general-cards",
                    //   // color: "info",
                    //   // label: "More about me",
                    // }}
                  />
                </MKBox>
              </Grid>

            
              <Grid item xs={12} sm={8} md={4} sx={{ mt: 0, mb: 6 }}>
                <MKBox mb={1}>
                  <SimpleBlogCard
                    image={andrew_linn}
                    name="Andrew Linn, MS"
                    title="CIO  & Data Scientist"
                    // description="Andrew Linn is the Chief Innovation Officer at Knowli Data Science. Leveraging a rich background in analytics and digital forensics, Andrew works with dedicated teams of data scientists and subject matter experts to guide the company into the future of AI and machine learning technologies. With his leadership, Knowli is harnessing collective expertise to innovate and deliver dynamic solutions to redefine industry standards and enhance client outcomes."
                    // action= {{
                    //   type: "internal",
                    //   // route: "/sections/page-sections/general-cards",
                    //   // color: "info",
                    //   // label: "More about me",
                    // }}
                  />
                </MKBox>
              </Grid>
              <Grid item xs={12} sm={8} md={4} sx={{ mt: 0, mb: 6 }}>
                <MKBox mb={1} >
                  <SimpleBlogCard
                    image={amy_thorn}
                    name="Amy Thorn, JD, MPH"
                    title="General Counsel & Data Scientist"
                    // description="Andrew Linn is the Chief Innovation Officer at Knowli Data Science. Leveraging a rich background in analytics and digital forensics, Andrew works with dedicated teams of data scientists and subject matter experts to guide the company into the future of AI and machine learning technologies. With his leadership, Knowli is harnessing collective expertise to innovate and deliver dynamic solutions to redefine industry standards and enhance client outcomes."
                    // action= {{
                    //   type: "internal",
                    //   // route: "/sections/page-sections/general-cards",
                    //   // color: "info",
                    //   // label: "More about me",
                    // }}
                  />
                </MKBox>
              </Grid>
              </Grid>
              <Grid container spacing={6} justifyContent="center" sx={{ mt: 0, mb: 6 }}>
              <Grid item xs={12} sm={8} md={4.5} sx={{ mt: 0, mb: 6 }}>
                <MKBox mb={1}>
                    <SimpleBlogCard
                    // sx={{minHeight:"200px"}}
                        image={belal_ghaffari}
                        name="Belal Ghaffari, MD, MPH"
                        title="CMO & Data Scientist"
                        // description="Andrew Linn is the Chief Innovation Officer at Knowli Data Science. Leveraging a rich background in analytics and digital forensics, Andrew works with dedicated teams of data scientists and subject matter experts to guide the company into the future of AI and machine learning technologies. With his leadership, Knowli is harnessing collective expertise to innovate and deliver dynamic solutions to redefine industry standards and enhance client outcomes."
                        // action={{
                        //     type: "internal",
                        //     // route: "/sections/page-sections/general-cards",
                        //     color: "info",
                        //     label: "More about me",
                        // }}
                    />
                </MKBox>
            </Grid>
            <Grid item xs={12} sm={8} md={4.5} sx={{ mt: 0, mb: 6 }}>
                <MKBox mb={1}>
                    <SimpleBlogCard
                        // sx={{minHeight:"200px"}}
                        image={danielle_sharp}
                        name="Danielle Sharp"
                        title="Director of Marketing And Business Development"
                        // description="Andrew Linn is the Chief Innovation Officer at Knowli Data Science. Leveraging a rich background in analytics and digital forensics, Andrew works with dedicated teams of data scientists and subject matter experts to guide the company into the future of AI and machine learning technologies. With his leadership, Knowli is harnessing collective expertise to innovate and deliver dynamic solutions to redefine industry standards and enhance client outcomes."
                        // action={{
                        //     type: "internal",
                        //     // route: "/sections/page-sections/general-cards",
                        //     color: "info",
                        //     label: "More about me",
                        // }}
                    />
                </MKBox>
            </Grid>
        </Grid>
          </Container>
        </MKBox>
      
      </MKBox>
      <MKBox position="relative" variant="gradient" sx={{backgroundColor:"#005c89"}} py={2} mx={-2}>
      <MKBox
        component="img"
        src={bgPattern}
        alt="background-pattern"
        position="absolute"
        top={0}
        left={0}
        width="100%"
        opacity={0.2}
        display={{ xs: "none", md: "block" }}
      />
      <Container>
        <Grid
          container
          justifyContent="center"
          sx={{ pt: 8, pb: 5, position: "relative", zIndex: 3 }}
        >
          <Grid item xs={12} md={6} sx={{ textAlign: "center" }}>
            <MKBadge
              badgeContent="Knowli's Brain Power"
              variant="contained"
              color="white"
              size="sm"
              container
              sx={{ mb: 1 }}
            />
            <MKTypography variant="h2" color="white" mb={1}>
              Our Awesome Team
            </MKTypography>

          </Grid>
          </Grid>




        <Grid container spacing={3} sx={{ mt: 0, mb: 6 }}>
          <Grid item xs={12}  sm={6} md={4}     sx={{
            '@media (max-width:1000px)': {
              flexBasis: '50%', // 2 cards per row at 1000px
              maxWidth: '50%',
            },
          }}>
            <HorizontalTeamCard
              image={tim_arthur}
              name="Tim Arthur PhD"
              position={{
                label: "Data Scientist", 
                color:"text"}}
              description="Sociology"
            
           
            />
          </Grid>
          <Grid item xs={12}  sm={6} md={4}     sx={{
            '@media (max-width:1000px)': {
              flexBasis: '50%', // 2 cards per row at 1000px
              maxWidth: '50%',
            },
          }}>
            <HorizontalTeamCard
              image={evan_barksdale}
              name="Evan Barksdale MS"
              position={{
                label: "Data Science Team Lead", 
                color:"text"}}
              description="Geographic Information Science"
   
            />
          </Grid>
          <Grid item xs={12}  sm={6} md={4}     sx={{
            '@media (max-width:1000px)': {
              flexBasis: '50%', // 2 cards per row at 1000px
              maxWidth: '50%',
            },
          }}>
            <HorizontalTeamCard
              image={briann_booth}
              name="Briann Booth MS"
              position={{
                label: "AI/ML Engineer", 
                color:"text"}}
              description="Mathematics & C.S."
          
            />
          </Grid>

          <Grid item xs={12}  sm={6} md={4}     sx={{
            '@media (max-width:1000px)': {
              flexBasis: '50%', // 2 cards per row at 1000px
              maxWidth: '50%',
            },
          }}>
            <HorizontalTeamCard
              image={tyler_bruefach}
              name="Tyler Bruefach PhD"
              position={{
                label: "Data Scientist", 
                color:"text"}}
              description="Sociology"
             
            />
          </Grid>
          <Grid item xs={12}  sm={6} md={4}     sx={{
            '@media (max-width:1000px)': {
              flexBasis: '50%', // 2 cards per row at 1000px
              maxWidth: '50%',
            },
          }}>
            <HorizontalTeamCard
              image={heedeok_cho}
              name="Heedeok Cho PhD"
              sx={{"minHeight":"500px"}}
              position={{
                label: "Data Scientist", 
                color:"text"}}
              description="Design, Construction & Planning"
           
            />
          </Grid>
          <Grid item xs={12}  sm={6} md={4}     sx={{
            '@media (max-width:1000px)': {
              flexBasis: '50%', // 2 cards per row at 1000px
              maxWidth: '50%',
            },
          }}>
            <HorizontalTeamCard
              image={kurt_van_etten}
              name="Kurt Van Etten BA"
              position={{
                label: "Data Scientist", 
                color:"text"}}
              description="Mathematics"
             
            />
          </Grid>


          <Grid item xs={12}  sm={6} md={4}     sx={{
            '@media (max-width:1000px)': {
              flexBasis: '50%', // 2 cards per row at 1000px
              maxWidth: '50%',
            },
          }}>
            <HorizontalTeamCard
              image={rishita_gupta}
              name="Rishita Gupta MS"
              position={{
                label: "Data Scientist", 
                color:"text"}}
              description="Business Analytics"
           
            />
          </Grid>
          <Grid item xs={12}  sm={6} md={4}     sx={{
            '@media (max-width:1000px)': {
              flexBasis: '50%', // 2 cards per row at 1000px
              maxWidth: '50%',
            },
          }}>
            <HorizontalTeamCard
              image={robert_johnson}
              name="Robert Johnson CPM"
              position={{
                label: "Data Scientist", 
                color:"text"}}
              description="Certified Public Manager"
        
            />
          </Grid>
          <Grid item xs={12}  sm={6} md={4}     sx={{
            '@media (max-width:1000px)': {
              flexBasis: '50%', // 2 cards per row at 1000px
              maxWidth: '50%',
            },
          }}>
            <HorizontalTeamCard
              image={sadaf_martin}
              name="Sadaf Z. Martin MS"
              position={{
                label: "Data Scientist", 
                color:"text"}}
              description="Demography & Population Study"
             
            />
          </Grid>

  

          
          <Grid item xs={12}  sm={6} md={4}     sx={{
            '@media (max-width:1000px)': {
              flexBasis: '50%', // 2 cards per row at 1000px
              maxWidth: '50%',
            },
          }}>
            <HorizontalTeamCard
              image={sachin_murthy}
              name="Sachin Murthy MS"
              position={{
                label: "Data Scientist", 
                color:"text"}}
              description="Applied Data Science"
            />
          </Grid>
         
          <Grid item xs={12}  sm={6} md={4}     sx={{
            '@media (max-width:1000px)': {
              flexBasis: '50%', // 2 cards per row at 1000px
              maxWidth: '50%',
            },
          }}>
            <HorizontalTeamCard
              image={matt_russo}
              name="Matthew Russo PhD"
              position={{
                label: "Data Scientist", 
                color:"text"}}
              description="Mathematics"
        
            />
          </Grid>
        
          <Grid item xs={12}  sm={6} md={4}     sx={{
            '@media (max-width:1000px)': {
              flexBasis: '50%', // 2 cards per row at 1000px
              maxWidth: '50%',
            },
          }}>
            <HorizontalTeamCard
              image={kyle_saunders}
              name="Kyle Saunders PhD"
              position={{
                label: "Data Scientist", 
                color:"text"}}
              description="Sociology"
             
            />
          </Grid>
     

          
          <Grid item xs={12}  sm={6} md={4}     sx={{
            '@media (max-width:1000px)': {
              flexBasis: '50%', // 2 cards per row at 1000px
              maxWidth: '50%',
            },
          }}>
            <HorizontalTeamCard
              image={marten_scheffers}
              name="Marten Scheffers PhD"
              position={{
                label: "Data Science Team Lead", 
                color:"text"}}
              description="Neuroscience"
           
            />
          </Grid>
          
          <Grid item xs={12}  sm={6} md={4}     sx={{
            '@media (max-width:1000px)': {
              flexBasis: '50%', // 2 cards per row at 1000px
              maxWidth: '50%',
            },
          }}>
            <HorizontalTeamCard
              image={alan_staney}
              name="Alan Staney PSM"
              position={{
                label: "Data Scientist", 
                color:"text"}}
              description="Professional Scrum Master"
        
            />
          </Grid>
         
          <Grid item xs={12}  sm={6} md={4}     sx={{
            '@media (max-width:1000px)': {
              flexBasis: '50%', // 2 cards per row at 1000px
              maxWidth: '50%',
            },
          }}>
            <HorizontalTeamCard
              image={curtis_warren}
              name="Curtis Warren MPH"
              position={{
                label: "Data Scientist", 
                color:"text"}}
              description="Public Health"
             
            />
          </Grid>
    </Grid>

      </Container>
    </MKBox>
    </>
  );
}

export default Bio;