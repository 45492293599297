import React, { useState, useEffect } from 'react';

const Typewriter = ({ text, delay }) => {
  const [currentText, setCurrentText] = useState('');
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {

    if (text && currentIndex < text.length) {
      const timeout = setTimeout(() => {
        setCurrentText(prevText => prevText + text[currentIndex]);
        setCurrentIndex(prevIndex => prevIndex + 1);
      }, delay);
  
      return () => clearTimeout(timeout);
    }
  }, [currentIndex, delay, text]);

  const parseText = (inputText) => {
    // Replace markdown-like formatting with HTML tags
    const formattedText = inputText
      .replace(/\*\*(.*?)\*\*/g, '<b>$1</b>')  // Bold
      .replace(/\*(.*?)\*/g, '<i>$1</i>')     // Italic
      .replace(/\n/g, '<br>');                // New lines
    
    return { __html: formattedText };
  };

  return (<span dangerouslySetInnerHTML={parseText(currentText)}></span>);
};

export default Typewriter;