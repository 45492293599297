/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================
*/

// @mui material components




import Bio from "pages/bio";

import PageContainer from 'examples/ExteriorDrawer/Drawer';
import KnowliProjects from "pages/knowliProject";


const routes = [

  {
    name: "Projects",
    route: "/pages/projects",
    key: "projects",
    component: <PageContainer />,
  },
  {
    name: "Dashboards",
    route: "/pages/knowli-dashboards",
    key: "projects",
    component: <KnowliProjects />,
  },
  {
    name: "Our Team",
    route: "/pages/bio",
    key: "bio",
    component: <Bio />,
  },

  // {
  //   name: "Sign In",
  //   icon: <Icon>login</Icon>,
  //   route: "/pages/Authentication/sign_in",
  //   key: "sign_in",
  //   component: <SignInCover />,
  // }

];

export default routes;
