import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { mdiFilePdfBox } from '@mdi/js';
import Icon from '@mdi/react';
import {
  AppBar,
  Toolbar,
  Typography,
  Paper,
  Grid,
  Checkbox,
  Container,
  CssBaseline,
  MenuItem, 
  Box, 
  useMediaQuery,
  Switch, 
  FormGroup, 
  FormControl,
  FormControlLabel ,
  InputLabel,
  Select,
  Modal,
  OutlinedInput,
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import CircularProgress from '@mui/material/CircularProgress';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import TextField from '@mui/material/TextField';
import MKBox from "components/MKBox";
import MKButton from 'components/MKButton';
import PDFViewer from 'components/PDFViewer';
import theme from "assets/theme";
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';





const serverBaseURL = process.env.REACT_APP_BACKEND_URL;
//const serverBaseURL = "http://localhost:8000"


const customtheme = createTheme(theme,{
  components: {
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: 'rgba(0, 0, 0, 0.23)', // Default border color
          '&:hover': {
            borderColor: 'rgba(0, 0, 0, 0.87)', // Darker on hover
          },
          '&.Mui-focused': {
            borderColor: '#333', // Darker black for focus state
          }
        }
      }
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: 'rgba(0, 0, 0, 0.87)', // Ensures hover effect matches focus
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: '#333', // Darker black for focus state
          }
        }
      }
    },
  }
});

const Jumbotron = styled(Box)(({ theme }) => ({
  background: 'linear-gradient(to right,#003366, #00AACC, #003366)',
  padding: theme.spacing(4),
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  color: theme.palette.common.white,
  marginBottom: theme.spacing(3)
}));


const KeywordVectorSearchPage = () => {

    const [formValues, setFormValues] = useState({
      searchTerm: '',
      state: [],
      vectorStatement: '',

    });
    const [checkedFacets, setCheckedFacets] = useState(new Set());
    const [loading, setLoading] = useState(false)
    const [allHighlights, setAllHighlights] = useState([])
    const [currentPage, setCurrentPage] = useState(1);

    // const [selectedFacets, setSelectedFacets] = useState([])
    const [searchType, setSearchType] = useState("keyword")
    const [bookmark, setBookmark]= useState(false)
    const [customBookmarks, setCustomBookmarks] = useState([])
    // const theme = useTheme();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [searchTerm, setSearchTerm] = useState("")
    const [states , setStates] = useState([])
    const [errors, setErrors] = useState({});
    const [facets, setFacets] = useState([])
    const [results, setResults] = useState([]);
    const [returnStatement, setReturnStatement] = useState("")
    const [resultsLength, setResultsLength] = useState(0)
    const [currentItems, setCurrentItems] = useState([])
    const [layoutToggle, setLayoutToggle] = useState(false);
    const commonInputStyle = {
      height: "50px",
      backgroundColor: "#fff", // Adjusted to white to match the TextField
      color: "#444",
      // border: "1px solid #ccc", // Adjusted to match TextField border
      borderRadius: "6px", // Ensure rounded corners similar to TextField
      "&:focus-within": {
        borderColor: "#222", // Darker black for focus state
      },


    };
    let indexOfLastItem;
    let indexOfFirstItem;
    const itemsPerPage=10
 
    const handleToggleChange = (event) => {
        setFormValues({ searchTerm: '',
          state: [],
          vectorStatement: '',
    
        })
        setAllHighlights([])
        setSearchTerm("")
        setFacets([])
        setResults([])
        setResultsLength(0)
        setCurrentItems([])
        setCurrentPage(1)
        if (searchType === "vector"){
          setSearchType("keyword")
        }
        else {
          setSearchType("vector")
        }
        setLayoutToggle(event.target.checked);
    };

    const handleCheckboxChange = (event) => {
      const { name } = event.target;
      const newSet = new Set(checkedFacets);
      if (newSet.has(name)) {
          newSet.delete(name);
      } else {
          newSet.add(name);
      }
      setCheckedFacets(newSet);
  };

    const handleChange = (e) => {
      const { name, value } = e.target;
      if (name === 'state') {
        setFormValues(prevValues => ({
          ...prevValues,
          [name]: typeof value === 'string' ? value.split(',') : [...value] // Ensures an array is always used
        }));
      } else {
        setFormValues(prevValues => ({
          ...prevValues,
          [name]: value
        }));
      }
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: false,
      }));
    };


  const handleSearch = async (event) => {
    event.preventDefault();
    setLoading(true);
    setFacets([])
    // setSelectedFacets([])
    setSearchType("keyword")
    setCurrentPage(1)
    
    setBookmark(true)
    setSearchTerm(formValues.searchTerm)
    setAllHighlights([formValues.searchTerm])
    
    
    if (formValues.state.length===0){
      formValues.state=["all"]
    }
    setStates(formValues.state)
    try {
      const response = await fetch(`${serverBaseURL}/search-api/keyword-search`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ term:`${formValues.searchTerm}`, states:formValues.state}),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok now');
      }
      setCurrentPage(1)
      const results = await response.json();
      if(results.file_information.length === 0){
        setReturnStatement(" 0 Record Found")
      }
      indexOfLastItem = currentPage * itemsPerPage;
      indexOfFirstItem = indexOfLastItem - itemsPerPage;
      setLoading(false);

      setCurrentItems(results.file_information.slice(indexOfFirstItem, indexOfLastItem));
      setResults(results.file_information);
      setResultsLength(results.file_information.length)
      setFacets(results.facets)




      console.log("this is the results of the query",results)
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };


  const handleFilterSearch = async (event) =>{
    event.preventDefault(); // Prevent the default form submit action
    setCurrentPage(1)
    setLoading(true);

    console.log("This is event target ", event.target)
    const formData = new FormData(event.target);
    const selectedFacets = [];
    
    // Get all 'facets' (assumed your checkboxes have name 'facets')
    formData.forEach((value, key) => {
        if ( value === 'on') {  // Assuming checkbox doesn't have a specific value and 'on' indicates checked
            selectedFacets.push(key);
        }
    });
    const filterFormData = {
        term: searchTerm,
        topics: selectedFacets,
        states: states
    };
    
    try {
      const response = await fetch(`${serverBaseURL}/search-api/get-filtered-docs`,
        {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(filterFormData)
      }
      );
      setCurrentPage(1)
      const results = await response.json();
      setLoading(false);

      if(results.file_information.length === 0){
        setReturnStatement(" 0 Record Found")
      }
      setAllHighlights([searchTerm, ...selectedFacets])
      indexOfLastItem = 1 * itemsPerPage;
      indexOfFirstItem = indexOfLastItem - itemsPerPage;
      setCurrentItems(results.file_information.slice(indexOfFirstItem, indexOfLastItem));
    
      setResults(results.file_information);
      setResultsLength(results.file_information.length)
      setFacets(results.facets)
      
      
      console.log("this is the results of the query",results)
  } catch (error) {
      console.error('Error with fetch:', error);
      // Handle errors here
  }

  }

  const handleVectorSearch = async (event) => {
    event.preventDefault();
    setLoading(true);

    setSearchType("vector")
    setSearchTerm(formValues.searchTerm)
    if (formValues.state.length===0){
      formValues.state=["all"]
    }
    setAllHighlights([formValues.searchTerm])
    setStates(formValues.state)

    setReturnStatement("")

   
    try {
      const response = await fetch(`${serverBaseURL}/search-api/vector-search`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ statement:`${formValues.vectorStatement}`, term:`${formValues.searchTerm}`, states:formValues.state}),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok now');
      }
     
      const results = await response.json();
      if(results.length === 0){
        setReturnStatement(" 0 Record Found")
      }
      setLoading(false);

      setResults(results);
    
      // setResultsLength(results.file_information.length)
      // console.log("this results.file_information.length ",results.file_information.length )
    } catch (error) {
      console.error('Error fetching search results:', error);
    }
  };
  useEffect(() =>{
    if (results) {
      const indexOfLastItem = currentPage * 10;
      const indexOfFirstItem = indexOfLastItem - 10;
      setCurrentItems(results.slice(indexOfFirstItem, indexOfLastItem));
    }

  }, [currentPage, results])

  useEffect(() => {
      // Initialize or update the checked status based on existing selections
      const newCheckedFacets = new Set([...checkedFacets].filter(facet => facets.some(f => f.keyword === facet)));
      setCheckedFacets(newCheckedFacets);
    }, [facets, checkedFacets]);

  return (
    <ThemeProvider theme={customtheme}>
    <Container sx={{height:"100vh",width:"100%", padding:0, backgroundColor:"linear-gradient(to right,#003366, #00AACC, #003366)"}}>
    
    <CssBaseline />
    <AppBar position="static">
      <Toolbar>
      
      </Toolbar>
    </AppBar>


    <Jumbotron>
    <FormGroup sx={{color:"#fff"}}>
        <FormControlLabel
            control={
              <Switch
                checked={layoutToggle}
                onChange={handleToggleChange}
                sx={{
                  '& .MuiSwitch-track': {
                    backgroundColor: '#fff', // Override the track color
                  },
                  '& .MuiSwitch-thumb': {
                    color: '#fff', // Override the thumb color
                  },
                }}
              />
            }
            label={layoutToggle ? "Switch to Keyword Search" : "Switch to Vector Search"}
            sx={{
              color: '#fff', // Override the label color
              '& .MuiTypography-root': { // Targets the Typography component within FormControlLabel
                color: '#fff', // Ensure the text color is white
              }
            }}
        />
    </FormGroup>
  {!layoutToggle ? (

<form onSubmit={handleSearch}>
<Box display="flex" flexDirection={isSmallScreen ? 'column' : 'row'} gap={2}  alignItems="center">
<Typography variant="h4" gutterBottom sx={{color:"#fff"}}>
       Keyword Search
    </Typography>
            <TextField
            required
            fullWidth 
              placeholder="Site search..."
              InputProps={{
                
                startAdornment: <SearchIcon />,
                type: 'search',
                // sx:{height:"50px",
                //   backgroundColor: "#ccc",
                //   color:"#444"
                // }
                sx: commonInputStyle
              }}
              variant="outlined"
              // size="small"
              name="searchTerm"
              value={formValues.searchTerm}
              onChange={handleChange}
              error={errors.searchTerm}
           
            />
          
          <FormControl fullWidth variant="outlined" error={errors.state}>
          <InputLabel id="state-label">Medicaid State Policy</InputLabel>
          <Select
            labelId="state-label"
            id="state"
            name="state"
            multiple
         
            value={formValues.state}
            onChange={handleChange}
            input={<OutlinedInput labelWidth={0} />}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 224,
                  width: 250,
                },
              },
            }}
            sx={{
              ...commonInputStyle,
              ".MuiSelect-select": commonInputStyle // Ensures inner Select matches style
            }}
            InputProps={{
           
              sx: {
                backgroundColor: "#ccc",
                color: "#444",
                height: "50px"
              }
            }}
          >
            <MenuItem value="florida">Florida State Medicaid</MenuItem>
            <MenuItem value="michigan">Michigan State Medicaid</MenuItem>
            <MenuItem value="south_carolina">South Carolina State Medicaid</MenuItem>
            <MenuItem value="all">All</MenuItem>
          </Select>
        </FormControl>
                
 
              
           

        <MKButton className="btn btn-primary" type="submit" sx={{height:"50px"}} >
          Search
        </MKButton>
  


      </Box>

      </form> 
  ):(
    
<form onSubmit={handleVectorSearch}>
<Box display="flex" flexDirection={isSmallScreen ? 'column' : 'row'} gap={2}>
    <Typography variant="h4" gutterBottom sx={{color:"#fff"}}>
        Vector Search
    </Typography>
    <Grid container spacing={2} alignItems="center">
    <Grid item sm={12} md={6}>
    <TextField 
        fullWidth 
        required
        label="Search Statement" 
        name="vectorStatement"
        variant="outlined" 
        value={formValues.vectorStatement}
        onChange={handleChange}
        error={errors.vectorStatement}
        multiline
        rows={4}
        placeholder="Enter search term..."
        sx={{ marginBottom: 2 , backgroundColor: "#fff", borderRadius:"5px",height:'100px'}}
    />
</Grid>
<Grid item sm={12} md={4}>
<TextField
            fullWidth
            placeholder="Keyword Filter"
            InputProps={{
              
              startAdornment: <SearchIcon />,
              type: 'search',
              sx:{height:"50px",
                backgroundColor: "#fff",
                color: "#444",
                marginBottom:"10px",
                marginTop:"-10px"
              }
            }}
            variant="outlined"
            size="small"
            name="searchTerm"
            value={formValues.searchTerm}
            onChange={handleChange}
            error={errors.searchTerm}
         
          />
          <FormControl fullWidth variant="outlined" error={errors.state}>
          <InputLabel id="state-label">Medicaid State Policy</InputLabel>
          <Select
       
            labelId="state-label"
            id="state"
            name="state"
            multiple
            value={formValues.state}
            onChange={handleChange}
            input={<OutlinedInput labelWidth={0} />}
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: 224,
                  width: 250,
                  borderRadius: "5px"
                },
              },
            }}
            sx={{
              ...commonInputStyle,
              ".MuiSelect-select": commonInputStyle ,
              backgroundColor:"#fff" ,color: "#444", height:"50px",borderRadius: "5px"// Ensures inner Select matches style
            }}
            InputProps={{
           
              sx: {
                backgroundColor: "#ccc",
                color: "#444",
                height: "50px",
                borderRadius: "5px"
              }
            }}
          >
            <MenuItem value="florida">Florida State Medicaid</MenuItem>
            <MenuItem value="michigan">Michigan State Medicaid</MenuItem>
            <MenuItem value="south_carolina">South Carolina State Medicaid</MenuItem>
            <MenuItem value="all">All</MenuItem>
          </Select>
        </FormControl>
      </Grid>
              
    <Grid item xs={12} sm={2}>
    <MKButton className="btn btn-primary" sx={{height:"50px"}} type="submit">
        Search
    </MKButton>
    </Grid>
    </Grid>
</Box>
</form>
  )}
  </Jumbotron>
  {loading ? (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '200px' }}>
            <CircularProgress />
          </div>
        ) : (
    <>
  {searchType ==="keyword" ? (
    <Grid container spacing={3} style={{ marginTop: '20px', minHeight:"200px" }}>
      <Grid item xs={12} sm={3}>
        <Paper style={{ padding: '10px' }}>
          <Typography variant="h6">Common Topics</Typography>
        <Box component="form" onSubmit={handleFilterSearch} noValidate sx={{ mt: 1 }}>
          <div style={facets.length === 0 ? { marginBottom: "-7px", fontSize: "12px" } : { padding: "10px", fontSize: "12px" }}>
          {facets.length > 0 ?(
            <MKButton variant="contained" sx={{ height: "50px", backgroundColor: "#1baad1", color: "#ffffff" }} fullWidth type="submit" >
            Submit
          </MKButton>):(
            <></>
          )}
         
          <FormGroup>
            {facets.map(
              (item) => (
                <FormControlLabel key={item["keyword"]} control={
                  <Checkbox edge="start" name={item["keyword"]}  checked={checkedFacets.has(item["keyword"])} onChange={handleCheckboxChange} />}
                  label={`${item["keyword"]}`} sx={{fontSize:"12px"}}/>

              )
            )}
          </FormGroup>
          </div>
          </Box>
        </Paper>
      </Grid>

      <Grid item xs={12} sm={9}>
        <Paper style={{ padding: '10px' }}>
          {resultsLength === 0 ?(
            <Typography variant="h6">
            {`Search Results ${returnStatement}`}
          </Typography>
          ):(
            <Typography variant="h6">
            {`Search Results For ${searchTerm} : ${resultsLength}`}
          </Typography>
          )}
          
          
          {currentItems.map((result, index) => (
            <ExpandableArticle key={result.id} result={result} index={index} searchTerm={allHighlights} state={result.state} bookmark={bookmark} customBookmarks={customBookmarks} searchType={searchType} setCustomBookmarks={setCustomBookmarks}/>
          ))}
          {results.length > 9 ?(
          <MKBox>
          <MKButton onClick={() => setCurrentPage(prevPage => Math.max(prevPage - 1, 1))} disabled={currentPage === 1}>
            Previous
          </MKButton >
          <MKButton  onClick={() => setCurrentPage(prevPage => Math.min(prevPage + 1, Math.ceil(results.length / itemsPerPage)))} disabled={currentPage === Math.ceil(results.length / itemsPerPage)}>
            Next
          </MKButton >
        </MKBox>
          ):(
            <></>
          )}

        </Paper>
      </Grid>
    </Grid>
 ):(      
 <Grid item xs={12} sm={9}>
  <Paper style={{ padding: '10px' }}>
  {results.length === 0 ?(
            <Typography variant="h6">
            {`Search Results ${returnStatement}`}
          </Typography>
          ):(
            <Typography variant="h6">
            {`Search Results For ${searchTerm} : ${resultsLength}`}
          </Typography>)
  }
    {results.map((result, index) => (
      <ExpandableArticle key={result.id} result={result} index={index} searchTerm={allHighlights} state={formValues.state} bookmark={bookmark} customBookmarks={customBookmarks} searchType={searchType} setCustomBookmarks={setCustomBookmarks}/>
    ))}
  </Paper>
</Grid>
)}
</>)}

 </Container>
  </ThemeProvider>
)};

const ExpandableArticle = ({ result, searchTerm , index, state, bookmark, customBookmarks, searchType, setCustomBookmarks}) => {

  // const [highlights, setHighlights]=useState("")
  const [modalIsOpen, setModalIsOpen]= useState("")
  const [pdfUrl, setPdfUrl]= useState("")

  const handleTitle =(title) =>{
    let decodedtitle = decodeURIComponent(title)
  
    let parts = decodedtitle.split(/[-_]/);
    return parts.join(" ");
  }

  function toTitleCase(str){
    return str.replace(
      /\w\S*/g,
      function(txt) {
        return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
      }
    );
  }
  const handleOpen = async (pdf, searchTerm, state) => {
    if (pdf !=="No Results Returned"){

    try {
        // Initiate both fetch operations concurrently
        const pdfFetchPromise = fetch(`${serverBaseURL}/search-api/get_citations`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({filename: pdf, folder:state })
        });


        const [pdfResponse] = await Promise.all([pdfFetchPromise]);

        if (!pdfResponse.ok) {
            throw new Error('Failed to fetch PDF.');
        }


        const blob = await pdfResponse.blob();
        const pdfUrl = URL.createObjectURL(blob);
        setPdfUrl(pdfUrl);

        setModalIsOpen(true)

        if (pdfUrl) {
          openModal()
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  }};

  const handleOpenFromVector = async (pdf, state) => {
    if (pdf !=="No Results Returned"){
    
    const filename = pdf;
    try {
        // Initiate both fetch operations concurrently
        const pdfFetchPromise = fetch(`${serverBaseURL}/search-api/get_citations`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({filename: filename, folder:state })
        });


        const [pdfResponse] = await Promise.all([pdfFetchPromise]);

        if (!pdfResponse.ok) {
            throw new Error('Failed to fetch PDF.');
        }
 

        const blob = await pdfResponse.blob();
        const pdfUrl = URL.createObjectURL(blob);
        setPdfUrl(pdfUrl);
        // setHighlights([])


        setModalIsOpen(true)

        if (pdfUrl) {
          openModal()
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
  }};


  const openModal = () =>{
    setModalIsOpen(true)
  }
  const closeModal = () => {
    setModalIsOpen(false);
    // setHighlights(null)
    setCustomBookmarks([])
    
  };


return (
  <>
  {searchType === "keyword" ? (
  <Paper style={{ padding: '10px', marginBottom: '10px' ,margin:"5px" }}>
    <Typography variant="h6" onClick={() => handleOpen(result.main_file,searchTerm, result.state)} style={{ cursor: 'pointer' }} >{`${toTitleCase(result.state)} : ${handleTitle(result.main_file)}`}</Typography>
    <Modal
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ 'display':'flex',
          'justifyContent': 'center',
          'alignItems': 'center',
          'position':' fixed',
          'top': 0,
          'left': 0,
         'width': '100%',
          'height': '100%',
          'background': 'rgba(10, 0, 0, 0.5)',/* Semi-transparent background */
          'z-index': 1000 /* Ensure it's on top */}}
      >
        <MKBox  >
          <Container >
       <PDFViewer pdfUrl={pdfUrl} highlights={searchTerm} bookmark={bookmark} customBookmarks={customBookmarks}/>
        </Container>
        </MKBox>
      </Modal>
  </Paper>
  ):(
  <Paper style={{ padding: '5px', marginBottom: '10px' ,margin:"5px" , background:"#bbb"}}>
    {/* <Typography variant="subtitle1" >{handleTitle(result.main_file)}</Typography> */}
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
         <Box display="flex" flexDirection="column">
        <MKBox>
        <Typography varient="body1" >{`Score: ${result.highest_score.toFixed(3)}`}</Typography>
        </MKBox>
        <MKBox sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="subtitle1" sx={{ marginRight: '8px' }}>
              {toTitleCase(handleTitle(result.data[0]["state"]))} : {handleTitle(result.main_file)}
              </Typography>
              <Icon 
                path={mdiFilePdfBox} 
                size={1} 
                onClick={() => handleOpenFromVector(result.main_file,result.data[0]["state"])} 
                style={{ cursor: 'pointer' }} 
              />
            </MKBox>
 
        </Box>
    </AccordionSummary>
      <AccordionDetails>

     <ResultsAccordion items={result.data} handleTitle={handleTitle} index={index} handleOpen={handleOpenFromVector} state={state}/>
     </AccordionDetails>
     </Accordion>
    <Modal
        open={modalIsOpen}
        onClose={closeModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        sx={{ 'display':'flex',
          'justifyContent': 'center',
          'alignItems': 'center',
          'position':' fixed',
          'top': 0,
          'left': 0,
         'width': '100%',
          'height': '100%',
          'background': 'rgba(10, 0, 0, 0.5)',/* Semi-transparent background */
          'z-index': 1000 /* Ensure it's on top */}}
      >
        <MKBox  >
          <Container >
       <PDFViewer pdfUrl={pdfUrl} highlights={searchTerm} bookmark={bookmark} customBookmarks={customBookmarks}/>
        </Container>
        </MKBox>
      </Modal>
  </Paper>  
  )}
  </>
);
};

export default KeywordVectorSearchPage;


const ResultsAccordion = ({ items , handleTitle, index, handleOpen, state}) => {
  return (
    <div>
    
      {items.map((item, index)=>(
        <Accordion key={index}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls={`panel${index}a-content`}
            id={`panel${index}a-header`}
            sx={{marginBottom:"5px"}}
          >
          <Box display="flex" flexDirection="column">
            <MKBox>
              <Typography variant="subtitle2">Highest Score: {item.sentences[0].score.toFixed(3)}</Typography>
            </MKBox>
            <MKBox sx={{ display: 'flex', alignItems: 'center' }}>
              <Typography variant="subtitle2" sx={{ marginRight: '8px' }}>
                {handleTitle(item.file_name)}
              </Typography>
              <Icon 
                path={mdiFilePdfBox} 
                size={1} 
                onClick={() => handleOpen(item.file_name, item.state)} 
                style={{ cursor: 'pointer' }} 
              />
            </MKBox>
          </Box>
          </AccordionSummary>
          <AccordionDetails >
            {item.sentences.map((detail, idx) => (
              <div key={detail.index}>
                  <MKBox>
                  <Typography variant="body2" sx={{"fontWeight":500}}>
                    Score : {detail.score.toFixed(3)}
                  </Typography>
                  <Typography variant="body2" paragraph>
                  {detail.text}
                </Typography>
                </MKBox>
              </div>
            ))}
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
};