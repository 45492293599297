// React imports
import React, { useEffect , useRef } from 'react';

// Third-party libraries
import Slider from 'react-slick';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// @mui/material components
import { Typography, Paper, Card, CardContent, CardMedia, CardActions, Button } from '@mui/material';
import Container from "@mui/material/Container";
import styled from '@mui/material/styles/styled';

// Local components
import MKBox from 'components/MKBox';

import DefaultHeader from "examples/Header/DefaultHeader";

// Local styles and assets
import 'pages/knowliProject/knowliProject.css';

import slide1 from 'assets/images/FL_Medicaid_Enrollment.png';
import slide2 from 'assets/images/PotentiallyPreventable.png';
import slide3 from 'assets/images/BirthOutcomes.png';
import slide4 from 'assets/images/BakerAct.png';
import slide5 from 'assets/images/StateOpioid.png';


const StyledPaper = styled(Paper)(({ theme }) => ({
  background: 'linear-gradient(to right,#003366, #00AACC, #003366)', // Gradient background
  color: 'white',
  padding: theme.spacing(2),
  borderRadius: 0, // Removes border radius to cover entire viewport edge-to-edge
  boxShadow: 'none', // Optional: remove shadow if you want a flat design across the page
  overflow: 'hidden',
  textAlign: 'center',
  fontFamily: 'Lato, sans-serif', 
  marginTop: '-200px',
  position: 'absolute', // Ensures it can cover all content
//   top: 0,
//   left: 0,
  width: '100vw', // Viewport width
//   height: '100vh', // Viewport height
  zIndex: -1, 
  overflowY: 'auto', // Enable vertical scrolling
  // Additional styles for scrollbars, if needed:
  '&::-webkit-scrollbar': {
    width: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: theme.palette.primary.main,
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-track': {
    background: 'white',
  }
}));

function Presentation() {
  const targetRef = useRef(null);

  const scrollToRef = () => {
    if (targetRef.current) {
      targetRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: '0',
    focusOnSelect: true,
    variableWidth: true,
//     responsive: [
//         {
//          breakpoint: 1100, // For tablets and smaller screens
//                 settings: {
//                         dots: true,
//                         infinite: true,
//                         speed: 500,
//                         slidesToShow: 2,
//                         slidesToScroll: 1,
//                         centerMode: true,
//                         centerPadding: '0',
//                         focusOnSelect: true,
//                         variableWidth: true,
//                 }
//               },
//         {
//           breakpoint: 768, // For tablets and smaller screens
//           settings: {
//             slidesToShow: 1, // Show only 1 slide on mobile
//             centerMode: false, // Disable center mode on small screens
//             variableWidth: false, // Disable variable width for consistency
//           }
//         },
//         {
//           breakpoint: 480, // For mobile screens
//           settings: {
//             slidesToShow: 1,
//             slidesToScroll: 1,
//             dots: true, // Keep dots for easier navigation on mobile
//           }
//         }
//       ],
 
    beforeChange: (current, next) => handleBeforeChange(current, next),
//     responsive: [{
//             breakpoint: 768,
//             settings: {
//                 slidesToShow: 1
//             }
//         }]
        responsive: [
                {
                breakpoint: 1300, // For tablets and smaller screens
                        settings: {
                                dots: true,
                                infinite: true,
                                speed: 500,
                                slidesToShow: 1,
                                slidesToScroll: 1,
                                centerMode: true,
                                centerPadding: '0',
                                focusOnSelect: true,
                                variableWidth: true,
                        }
                }
        ]


};

useEffect(() => {
    // Set the initialSlide to be the center item
    const slides = document.querySelectorAll('.slick-slide');
    slides.forEach(slide => {
            if (slide.dataset.index === "0") {
              slide.style.transform = 'scale(1)';
            } else {
              slide.style.transform = 'scale(.8)';
            }
          });
    
}, []);



const handleBeforeChange = (current, next) => {
    const slides = document.querySelectorAll('.slick-slide');
    slides.forEach(slide => {
      if (slide.dataset.index === next.toString()) {
        slide.style.transform = 'scale(1)';
      } else {
        slide.style.transform = 'scale(.8)';
      }
    });
  };

  return (
    <>
    <DefaultHeader string_list={['make the future accessible today', 
                                'empower businesses with AI-driven solutions', 
                                'transform your visions into AI realities', 
                                'unleash business potential through AI'
                                ]}  scrollToSection={scrollToRef}/>
    <StyledPaper >

<MKBox component="section" py={20} px={1} ref={targetRef}>
<Container>
<Typography variant="h2" gutterBottom sx={{ textShadow: "2px 2px 4px #222" ,color:"#fff", fontFamily:'Avenir, sans-serif', fontWeight:700, marginLeft:"30px",marginRight:"30px"}}>
   Empowering Florida's Public Health with Data-Driven Solutions from Knowli Data Science
</Typography>
<Typography variant="subtitle1" gutterBottom sx={{ textShadow: "2px 2px 4px #000" ,color:"#fff" ,fontFamily:"Avenir, sans-serif", fontWeight:400 , marginBottom:"30px"}}>
Knowli Data Science proudly supports public health agencies in their mission to improve health outcomes and decision-making through the power of data visualization. Our intuitive dashboards deliver critical insights that inform policy decisions and optimize resource distribution. We make complex data accessible and actionable, enabling state agencies to deliver timely and targeted interventions that truly meet community needs.
</Typography>
<Slider {...settings} >
   <Card sx={{ maxWidth: 300 }}>
           <CardMedia
                   sx={{ height: 150 }}
                   image={slide3}
                   title="Birth Outcomes Dashboard"
                   alt="Birth Outcomes Dashboard"
           />
           <CardContent>
                   <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:"bold", transform:"scale(1.5"}}>
                   Birth Outcomes
                   </Typography>
                   <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:"bold", transform:"scale(1.5"}}>
                   Dashboard
                   </Typography>
                   <Typography gutterBottom variant="h6" component="div">
                   Florida Agency for Health Care Administration
                   </Typography>
                   <Typography variant="body2" color="text.secondary" sx={{fontSize:"12px"}}>
                   Tracks critical maternal and infant health indicators, such as cesarean deliveries 
                   and preterm births, to support healthcare strategies aimed at improving prenatal and postnatal care outcomes.
                   </Typography>
           </CardContent>
           <CardActions>
                   <Button size="small" href=" https://ahca.myflorida.com/medicaid/medicaid-policy-quality-and-operations/medicaid-policy-and-quality/medicaid-quality/quality-initiatives" target="_blank" rel="noopener noreferrer">Learn more</Button>
                   <Button size="small" href="https://bi.ahca.myflorida.com/t/FLMedicaid/views/BirthOutcomesDashboardSeries-External/CS-AgeRaceDashboard?%3Adisplay_count=n&%3Aembed=y&%3AisGuestRedirectFromVizportal=y&%3Aorigin=viz_share_link&%3AshowAppBanner=false&%3AshowVizHome=n" target="_blank" rel="noopener noreferrer">Dashboard link</Button>
           </CardActions>
   </Card>
   <Card sx={{ maxWidth: 300 }}>
           <CardMedia
                   sx={{ height: 150 }}
                   image={slide4}
                   title="Baker Act Dashboard"
                   alt="Baker Act Dashboard"
           />
           <CardContent>
                   <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:"bold"}}>
                   Baker Act 
                   </Typography>
                   <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:"bold"}}>
                   Dashboard
                   </Typography>
                   <Typography gutterBottom variant="h6" component="div">
                   Florida Department of Children and Families
                   </Typography>
                   <Typography variant="body2" color="text.secondary" sx={{fontSize:"12px"}}>
                   Delivers detailed demographic and geographic insights into involuntary mental health holds in Florida, 
                   assisting in the analysis of crisis patterns and the enhancement of mental health interventions.
                   </Typography>
           </CardContent>
           <CardActions>
           <Button size="small" href="https://www.myflfamilies.com/BakerActDashboard" target="_blank" rel="noopener noreferrer">Learn more</Button>
           <Button size="small" href="https://www.myflfamilies.com/BakerActDashboard" target="_blank" rel="noopener noreferrer">Dashboard link</Button>
           </CardActions>
   </Card>
   <Card sx={{ maxWidth: 300 }}>
           <CardMedia
                   sx={{ height: 150 }}
                   image={slide5}
                   title="State Opioid Dashboard"
                   alt="State Opioid Dashboard"
           />
           <CardContent>
                   <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:"bold"}}>
                   State Opioid
                   </Typography>
                   <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:"bold"}}>
                   Dashboard
                   </Typography>
                   <Typography gutterBottom variant="h6" component="div">
                   Florida Department of Children and Families
                   </Typography>
                   <Typography variant="body2" color="text.secondary" sx={{fontSize:"12px"}}>
                   Analyzes opioid-related data, including overdose rates and hospital visits, 
                   with a predictive county vulnerability score to inform strategic planning and resource allocation in combating opioid abuse.
                   </Typography>
           </CardContent>
           <CardActions>
           <Button size="small" href="https://floridaopioidsettlement.com/resources/" target="_blank" rel="noopener noreferrer">Learn more</Button>
           <Button size="small" href="https://app.powerbigov.us/view?r=eyJrIjoiMjhkNzRmZWEtMDljZS00ZjMzLWExODctOGJmMDU3MjM4ODI0IiwidCI6ImY3MGRiYTQ4LWIyODMtNGM1Ny04ODMxLWNiNDExNDQ1YTk0YyJ9" target="_blank" rel="noopener noreferrer">Dashboard link</Button>
           </CardActions>
   </Card>
   <Card sx={{ maxWidth: 300 }}>
           <CardMedia
                   sx={{ height: 150 }}
                   image={slide1}
                   title="Florida Medicaid Enrollment Dashboard"
                   alt="Florida Medicaid Enrollment Dashboard"
           />
           <CardContent>
                   <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:"bold"}}>
                   Florida Medicaid Enrollment Dashboard
                   </Typography>
                   <Typography gutterBottom variant="h6" component="div">
                   Florida Agency for Health Care Administration
                   </Typography>
                   <Typography variant="body2" color="text.secondary"  sx={{fontSize:"12px"}}>
                   Provides comprehensive demographic insights and enrollment trends for the Florida Medicaid program, enabling policymakers to effectively allocate resources and understand regional healthcare dynamics.
                   </Typography>
           </CardContent>
           <CardActions>
           <Button size="small" href="https://ahca.myflorida.com/medicaid/medicaid-finance-and-analytics/medicaid-data-analytics/medicaid-data-visualization-series" target="_blank" rel="noopener noreferrer">Learn more</Button>
           <Button size="small" href="https://bi.ahca.myflorida.com/t/FLMedicaid/views/MedicaidEnrollmentDashboardExternal/EnrollmentFastFacts?%3Adisplay_count=n&%3Aembed=y&%3AisGuestRedirectFromVizportal=y&%3Aorigin=viz_share_link&%3AshowAppBanner=false&%3AshowVizHome=n" target="_blank" rel="noopener noreferrer">Dashboard link</Button>
           </CardActions>
   </Card>
   <Card sx={{ maxWidth: 300 }}>
           <CardMedia
                   sx={{ height: 150 }}
                   image={slide2}
                   title="Potentially Preventable Healthcare Events Dashboard"
                   alt="Preventable Healthcare Events Dashboard"
           />
           <CardContent>
                   <Typography gutterBottom variant="h5" component="div" sx={{fontWeight:"bold"}}>
                   Potentially Preventable Healthcare Events 
                   </Typography>
                   <Typography gutterBottom variant="h6" component="div">
                   Florida Agency for Health Care Administration
                   </Typography>
                   <Typography variant="body2" color="text.secondary"  sx={{fontSize:"12px"}}>
                   Highlights potentially preventable medical visits, readmissions, and admissions, aiding in the 
                   identification of inefficiencies and the improvement of patient management and healthcare system cost-efficiency.
                   </Typography>
           </CardContent>
           <CardActions>
           <Button size="small" href="https://ahca.myflorida.com/medicaid/medicaid-policy-quality-and-operations/medicaid-policy-and-quality/medicaid-quality/quality-initiatives" target="_blank" rel="noopener noreferrer">Learn more</Button>
           <Button size="small" href="https://bi.ahca.myflorida.com/t/FLMedicaid/views/PPEDashboard01_08_24/PPVsbyHealthPlan?%3Aembed=y&%3AisGuestRedirectFromVizportal=y" target="_blank" rel="noopener noreferrer">Dashboard link</Button>
           </CardActions>
   </Card>
</Slider>
</Container>
</MKBox>
</StyledPaper>

    </>
  );
}

export default Presentation;
