/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useEffect } from "react";

// react-router-dom components
import { Link , useLocation} from "react-router-dom";
import { useAuth } from "pages/authentication/AuthContext";

// @mui material components
import Card from "@mui/material/Card";
import { Modal, TextField } from '@mui/material';


// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from 'components/MKButton';
import {QRCodeSVG} from 'qrcode.react';

import TotpModal from 'pages/authentication/ToptModal'

import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

// Authentication layout components
import CoverLayout from "pages/authentication/components/CoverLayout";


// Images
import bgImage from "assets/images/bg-sign-in-cover.jpeg";


function SignInCover() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [rememberMe, setRememberMe] = useState(false);
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmNewPassword, setconfirmNewPassword] = useState('');
  const [openEmailModal, setOpenEmailModal] = useState(false); 
  const [openModal, setOpenModal] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [isTotpModalOpen, setIsTotpModalOpen] = useState(false);
  const [openTOTPModal, setOpenTOTPModal] = useState(false);
  const [totpCode, setTotpCode] = useState('');
  const [qrCodeData, setQrCodeData] = useState('');
  const [qrCodeString, setQrCodeString] = useState('');
  const location = useLocation();

  const {signIn, TotpSubmit, confirmTOTP, reset, forgotPassword, verifyEmail} = useAuth();
  const landingPageUrl = "/pages/projects"

  const handleOpenEmailModal = () => setOpenEmailModal(true);
  const handleCloseEmailModal = () => setOpenEmailModal(false);

  const handleOpenTOTPModal = (qrcode) => {
   
    setQrCodeData(qrcode)
    const urlParams = new URLSearchParams(qrcode.split('?')[1]);
    const secret = urlParams.get('secret');
 
    setQrCodeString(secret)
    setOpenTOTPModal(true);
  }
  const handleCloseTOTPModal = () => setOpenTOTPModal(false);

  const handleSignIn = (e) => {
    e.preventDefault();    

    if (rememberMe) {
      // Save the email to localStorage if rememberMe is checked
      localStorage.setItem('rememberedEmail', email);
    } else {
      // Clear the remembered email if rememberMe is not checked
      localStorage.removeItem('rememberedEmail');
    }

    signIn(email, password, setIsTotpModalOpen,handleOpenTOTPModal, handleOpenEmailModal, setQrCodeData);

    
  };


  // const TotpSubmit = (totpcode)=>{

   const handleTotpSubmit = (totpcode)=>{

     TotpSubmit(totpcode, landingPageUrl)

  }

  const handleForgotPassword=()=>{
    if (!email){
      alert("enter email")
      return
    }
    forgotPassword(email, setOpenModal)

  }

const handleReset=(e)=>{
  e.preventDefault();
  if (!passwordsMatch) {
    alert("Passwords do not match!");
    return;
}
  reset(newPassword, email, verificationCode, setOpenModal)
 }

 const handleVerifyEmail = () =>{
  
  if (!verificationCode) {
    alert("Enter Verification Code!");
    return;
}
verifyEmail(handleCloseEmailModal, verificationCode,handleOpenTOTPModal)
}



  const handleConfirmTOTP = () =>{
 
    confirmTOTP(totpCode,handleCloseTOTPModal)
  }

const handleClose = () => {
  setOpenModal(false); // Function to close the modal
};


    // Load the remembered username from localStorage when the component mounts
  useEffect(() => {
      const rememberedEmail = localStorage.getItem('rememberedEmail');
      if (rememberedEmail) {
        setEmail(rememberedEmail);
        setRememberMe(true);
      }
    }, [])

    useEffect(() => {
      // Check if there is an email in the state
      if (location.state?.email) {
          setEmail(location.state.email);
      }
  }, [location.state]);

  useEffect(() => {
    // Check if passwords match whenever password or confirmPassword changes
    setPasswordsMatch(newPassword === confirmNewPassword);
}, [newPassword, confirmNewPassword]);



  return (
    <CoverLayout image={bgImage}>
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <Card>
        <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign in
          </MKTypography>
          <MKTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to sign in
          </MKTypography>
        </MKBox>
        <MKBox pt={4} pb={3} px={3}>
          <MKBox component="form" role="form" onSubmit={handleSignIn}>
            <MKBox mb={2}>
              <MKInput
                type="email"
                label="Email"
                variant="standard"
                fullWidth
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                type="password"
                label="Password"
                variant="standard"
                fullWidth
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
            </MKBox>

            <MKBox mt={4} mb={1}>
              <MKButton type="submit" variant="gradient" color="info" fullWidth>
                Sign in
              </MKButton>
            </MKBox>
            
            <MKBox mt={3} mb={1} textAlign="center">
              <MKTypography variant="button" color="text">
                Forgot Password Reset ?{" "}
                <MKTypography
                  onClick={handleForgotPassword}
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                  sx={{cursor: 'pointer' }}
                >
                  Password
                </MKTypography>
              </MKTypography>
            </MKBox>
            <MKBox mt={3} mb={1} textAlign="center">
              <MKTypography variant="button" color="text">
                Don&apos;t have an account?{" "}
                <MKTypography
                  component={Link}
                  to="/pages/authentication/sign_up"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign up
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        </MKBox>
        <TotpModal
        isOpen={isTotpModalOpen}
        onSubmit={handleTotpSubmit}
        onClose={() => setIsTotpModalOpen(false)}
      />
      <Modal open={openEmailModal} onClose={handleCloseEmailModal} style={{bgColor:'white', width:'400px', margin:'auto',marginTop:'50px'}}>
      <Card sx={{paddingBottom:"15px"}}>
      <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Verify Email
          </MKTypography>
          <MKTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail in maximum 60 seconds
          </MKTypography>
        </MKBox>
          <TextField
            label="Verification Code"
            
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            sx={{marginTop:"10px",marginLeft:"10px", marginRight:"10px"}}
          />
          <MKButton variant="contained" color="info" onClick={handleVerifyEmail} sx={{display: 'flex', justifyContent:'center', margin: 'auto', mt:2}}>
            Verify Email
          </MKButton>
      
        
       </Card>
      </Modal>
      <Modal open={openTOTPModal} onClose={handleCloseTOTPModal} style={{bgColor:'white', width:'400px', margin:'auto',marginTop:'50px'}}>
        <Card sx={{paddingBottom:"15px"}}>
        <MKBox          
        variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center">

          <MKTypography color="white">Scan this QR code with your authenticator app </MKTypography>
          <MKTypography color='white' sx={{ fontSize: "0.9rem", margin:"15px"  }}>
            Or enter your secret code : {qrCodeString}
          </MKTypography>
          </MKBox>
          
          <MKBox
          sx={{margin:'auto'}}>
          {qrCodeData && <QRCodeSVG 
          value={qrCodeData} 
          bgColor="#ffffff"  // Set background to white
          fgColor="#000000"  // Set foreground to black (QR code color)
          size={100}         // You can adjust the size if necessary

          />}
          </MKBox>
          <TextField
            label="Enter the code from your app"
            sx={{marginTop:"10px",marginLeft:"10px", marginRight:"10px"}}
            value={totpCode}
            onChange={(e) => setTotpCode(e.target.value)}
          />

          <MKButton variant="contained" color="info" onClick={handleConfirmTOTP} sx={{display: 'flex', justifyContent:'center', margin: 'auto', mt:2}}>
            Verify Code
          </MKButton>
        
        </Card>
      </Modal>
        <Modal
          open={openModal}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{maxWidth:300 , margin:"auto", marginTop:"50px"}}
        ><Card >
          <MKBox 
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          // mx={2}
          // mt={-3}
          // py={4}
          // mb={1}
          pt={4} 
          pb={3} 
          px={3}
          textAlign="center"
        >
          <MKTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Reset Password
          </MKTypography>
          <MKTypography display="block" variant="button" color="white" my={1}>
          If you are in our system, you will receive an email with a verification code to reset your password. The code should arrive within a few minutes.
          </MKTypography>
        </MKBox>
        <MKBox pt={4} pb={3} px={3}>
          <MKBox component="form" role="form" onSubmit={handleReset}>
            <MKBox mb={4}>
              <MKInput
                type="email"
                label="Email"
                value={email}
                variant="standard"
                fullWidth
                InputLabelProps={{ shrink: true }}
                placeholder="john@email.com"
              />
            </MKBox>
            <MKBox mb={4}>
              <MKInput
                type="text"
                label="Reset Code"
                variant="standard"
                fullWidth
                onChange={(e)=>setVerificationCode(e.target.value)}
                InputLabelProps={{ shrink: true }}
                placeholder=""
              />
            </MKBox>
            <MKBox mb={4}>
              <MKInput
                type="password"
                label="Enter New Password"
                variant="standard"
                fullWidth
                onChange={(e)=>setNewPassword(e.target.value)}
                InputLabelProps={{ shrink: true }}
                placeholder=""
              />
            </MKBox>
            <MKBox mb={4}>
              <MKInput
                type="password"
                label="Confirm Password"
                variant="standard"
                fullWidth
                onChange={(e)=>setconfirmNewPassword(e.target.value)}
                InputLabelProps={{ shrink: true }}
                placeholder=""
              />
            </MKBox>
            {!passwordsMatch && confirmNewPassword && (
                    <MKBox style={{ color: 'red' }}>
                        Passwords do not match!
                    </MKBox>
                )}
            <MKBox mt={6} mb={1}>
              <MKButton variant="gradient" color="info" fullWidth type="submit">
                reset
              </MKButton>
            </MKBox>
          </MKBox>
        </MKBox>
        </Card>
        </Modal>
      </Card>
    </CoverLayout>
  );
}


export default SignInCover;
