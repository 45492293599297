import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
// import FormGroup from '@mui/material/FormGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import Switch from '@mui/material/Switch';
import { Link , Outlet} from 'react-router-dom';
import Icon from '@mdi/react';
import { Card,  CardActionArea } from '@mui/material';
import { mdiGraphql,mdiTextBoxSearchOutline,mdiMonitorDashboard,mdiHomeImportOutline, mdiLaptopAccount,mdiChat, mdiTextBoxSearch} from '@mdi/js';

// import { useThemeToggle} from './themeContext'   

import logodark2 from 'assets/images/logodark2.png';
import logo3 from 'assets/images/KnowliLogo.png';

const drawerWidth = 240;

const StyledIcon = styled(Icon)({
  color: '#005a87',
});

const getIcon = (index) => {
    switch (index) {
      case 1:
        return <StyledIcon path={mdiTextBoxSearchOutline} size={1} />;
        
      case 0:
        return <StyledIcon path={mdiTextBoxSearch} size={1}/>;
      case 2:
        return <StyledIcon path={mdiMonitorDashboard} size={1} />;
        
      case 3:
        return <StyledIcon path={mdiGraphql} size={1} />;
      default:
        return null;
    }
  };


  const getIcon2 = (index) => {
    switch (index) {
      case 1:
        return <StyledIcon path={mdiLaptopAccount} size={1} />;
      case 0:
        return <StyledIcon path={mdiChat} size={1} />;
      default:
        return null;
    }
  };

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));



const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  backgroundColor: theme.palette.mode === 'dark' ? '#333' : '#FFF',
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);
// const MaterialUISwitch = styled(Switch)(({ theme }) => ({
//   width: 62,
//   height: 34,
//   padding: 7,
//   '& .MuiSwitch-switchBase': {
//     margin: 1,
//     padding: 0,
//     transform: 'translateX(6px)',
//     '&.Mui-checked': {
//       color: '#fff',
//       transform: 'translateX(22px)',
//       '& .MuiSwitch-thumb:before': {
//         backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
//           '#fff',
//         )}" d="M4.2 2.5l-.7 1.8-1.8.7 1.8.7.7 1.8.6-1.8L6.7 5l-1.9-.7-.6-1.8zm15 8.3a6.7 6.7 0 11-6.6-6.6 5.8 5.8 0 006.6 6.6z"/></svg>')`,
//       },
//       '& + .MuiSwitch-track': {
//         opacity: 1,
//         backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
//       },
//     },
//   },
//   '& .MuiSwitch-thumb': {
//     backgroundColor: theme.palette.mode === 'dark' ? '#003892' : '#001e3c',
//     width: 32,
//     height: 32,
//     '&::before': {
//       content: "''",
//       position: 'absolute',
//       width: '100%',
//       height: '100%',
//       left: 0,
//       top: 0,
//       backgroundRepeat: 'no-repeat',
//       backgroundPosition: 'center',
//       backgroundImage: `url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" height="20" width="20" viewBox="0 0 20 20"><path fill="${encodeURIComponent(
//         '#fff',
//       )}" d="M9.305 1.667V3.75h1.389V1.667h-1.39zm-4.707 1.95l-.982.982L5.09 6.072l.982-.982-1.473-1.473zm10.802 0L13.927 5.09l.982.982 1.473-1.473-.982-.982zM10 5.139a4.872 4.872 0 00-4.862 4.86A4.872 4.872 0 0010 14.862 4.872 4.872 0 0014.86 10 4.872 4.872 0 0010 5.139zm0 1.389A3.462 3.462 0 0113.471 10a3.462 3.462 0 01-3.473 3.472A3.462 3.462 0 016.527 10 3.462 3.462 0 0110 6.528zM1.665 9.305v1.39h2.083v-1.39H1.666zm14.583 0v1.39h2.084v-1.39h-2.084zM5.09 13.928L3.616 15.4l.982.982 1.473-1.473-.982-.982zm9.82 0l-.982.982 1.473 1.473.982-.982-1.473-1.473zM9.305 16.25v2.083h1.389V16.25h-1.39z"/></svg>')`,
//     },
//   },
//   '& .MuiSwitch-track': {
//     opacity: 1,
//     backgroundColor: theme.palette.mode === 'dark' ? '#8796A5' : '#aab4be',
//     borderRadius: 20 / 2,
//   },
// }));
const CustomMenuIcon = styled(MenuIcon)({
  color: "#005a87"
})

export default function MiniDrawer() {
  const theme = useTheme();
  // const toggleTheme = useThemeToggle();
  const logo = theme.palette.mode === 'dark' ? logodark2 : logo3;


  const [open, setOpen] = React.useState(false);

  

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  

  return (
   
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      <AppBar position="fixed" open={open}>
        <Toolbar  style={{ display: 'flex', alignItems: 'center'}}>
          <IconButton
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: 'none' }),
            }}
          >
            <CustomMenuIcon />
          </IconButton>
          <Box sx={{ flexGrow: 1, display: 'flex', justifyContent: 'left' }}>
          <Link to="/">
          <img src={logo} alt="Logo" style={{ height: '40px' }}/>
        
          </Link>
        </Box>
          {/* <FormGroup style={{ display: 'flex', justifyContent: 'flex-end' }}>
            <FormControlLabel
              control={<MaterialUISwitch sx={{ m: 1 }} defaultChecked onChange={toggleTheme}/>}
              sx={{ ml: 'auto' }}
            />
        </FormGroup> */}
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader>
          <IconButton onClick={handleDrawerClose}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </DrawerHeader>
        <Divider />
        <List>
        <ListItem key={"home"} disablePadding sx={{ display: 'block' }}>
            <Card
            sx={{
              margin: '10px 0',
              boxShadow: 3,
              borderRadius: 2,
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
          > <CardActionArea component={Link} to={"/pages/projects"}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  <StyledIcon path={mdiHomeImportOutline} size={1} />
                </ListItemIcon>
                <ListItemText primary={"Main"}  primaryTypographyProps={{ fontSize: '18px' }}sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
              </CardActionArea>
              </Card>
            </ListItem>
        {['Policy Chat', 'Contract Analysis'].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block' }}>
            <Card
            sx={{
              margin: '10px 0',
              boxShadow: 3,
              borderRadius: 2,
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
          > <CardActionArea component={Link} to={text.toLowerCase().replace(/ /g, '-')}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {getIcon2(index)}
                </ListItemIcon>
                <ListItemText primary={text} primaryTypographyProps={{ fontSize: '18px' }} sx={{ opacity: open ? 1 : 0 }} />
              </ListItemButton>
              </CardActionArea>
              </Card>
            </ListItem>
          ))}
          {["Policy-Search"].map((text, index) => (
            <ListItem key={text} disablePadding sx={{ display: 'block'}}>
            <Card
            sx={{
              fontSize: "5px",
              margin: '10px 0',
              boxShadow: 3,
              borderRadius: 2,
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
          > <CardActionArea component={Link} to={text.toLowerCase().replace(/ /g, '-')}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                 
                  justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                  {getIcon(index)}
                </ListItemIcon>
                <ListItemText primary={text} primaryTypographyProps={{ fontSize: '18px' }} sx={{ opacity: open ? 1 : 0  }} />
              </ListItemButton>
              </CardActionArea>
              </Card>
            </ListItem>
          ))}
        {/* </List>

        <List> */}
          
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
       <Outlet/>
      </Box>
    </Box>

  );
}