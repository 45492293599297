// File: TotpModal.js
import { useState} from "react";
// @mui material components
import Card from "@mui/material/Card";
import Modal from "@mui/material/Modal";


// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from 'components/MKButton';




function TotpModal({ isOpen, onSubmit, onClose }) {
  const [totpCode, setTotpCode] = useState("");


  const handleFormSubmit=()=>{
    onSubmit(totpCode)
  }

  if (!isOpen) {
    return null; // If modal is not open, render nothing
  }

  return (
    <>
      <Modal
          open={isOpen}
          onClose={onClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          sx={{maxWidth:300 , margin:"auto", marginTop:"50px"}}
        > 
        <Card >
          <MKBox 
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"

          pt={4} 
          pb={3} 
          px={3}
          textAlign="center"
        >
          <MKTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Multi Factor Authentication
          </MKTypography>
          <MKTypography display="block" variant="button" color="white" my={1}>
            Please enter the code from your authenication Application
          </MKTypography>
        </MKBox>
        <MKBox pt={4} pb={3} px={3}>
          <MKBox component="form" role="form" onSubmit={onSubmit}>
  
            <MKBox mb={4}>
              <MKInput
                type="text"
                label="Code"
                variant="standard"
                value={totpCode}
                onChange={(e)=>setTotpCode(e.target.value)}
                InputLabelProps={{ shrink: true }}
                placeholder=""
              />
            </MKBox>

            <MKBox mt={6} mb={1}>
              <MKButton variant="gradient" color="info" onClick={handleFormSubmit}>
                Send
              </MKButton>
            </MKBox>
          </MKBox>
        </MKBox>
        </Card>
        
        
        </Modal>
        </>
    );
  }
  


export default TotpModal;

