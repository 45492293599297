import React, { useEffect, useState } from 'react';
import './TypingSpinner.css'; // Make sure to create this CSS file
import MKTypography from 'components/MKTypography'

const TypingSpinner = ({ message = "Loading" }) => {
  const [displayText, setDisplayText] = useState('');

  useEffect(() => {
    let messageIndex = 0;
    const typeMessage = () => {
      if (messageIndex < message.length) {
        setDisplayText((prev) => prev + message.charAt(messageIndex));
        messageIndex++;
      } else {
        setDisplayText(message);
        clearInterval(interval);
        // Start the ellipsis animation after the message is fully typed
        setDisplayText((prev) => `${prev}...`);
      }
    };
    const interval = setInterval(typeMessage, 150);

    return () => clearInterval(interval);
  }, [message]);

  return <MKTypography sx={{ color: '#666', fontSize:"30px" }} className="typing-spinner">{displayText}</MKTypography>;
};

export default TypingSpinner;
