import React, { createContext, useContext, useState } from 'react';
import {useNavigate} from "react-router-dom";
import { CognitoUserAttribute , AuthenticationDetails, CognitoUser } from "amazon-cognito-identity-js";
import userpool from "userpool";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const AuthContext = createContext();

export const useAuth = () => {
  return useContext(AuthContext);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [cognitoUser, setCognitoUser] = useState(null)
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  // const [qrCodeData, setQrCodeData] = useState('');
  
  
  const navigate = useNavigate();

  const signIn = (email, password,setIsTotpModalOpen,handleOpenTOTPModal,handleOpenEmailModal, handleCloseEmailModal, setQrCodeData) => {

    const authenticationData = {
      Username: email, // Using Username to hold email value as per Cognito's requirement
      Password: password,
  };
  const authenticationDetails = new AuthenticationDetails(authenticationData);

  const userData = {
      Username: email, // Username here is the email used as identifier
      Pool: userpool, // Ensure userPool is correctly initialized elsewhere in your code
  };
  const cognitoUser = new CognitoUser(userData);
  setCognitoUser(cognitoUser)

  cognitoUser.authenticateUser(authenticationDetails, {
    onSuccess: (result) => {

      // setUser(cognitoUser); // Save the cognitoUser to use later for TOTP
      const idToken = result.getIdToken().getJwtToken();
      const accessToken = result.getAccessToken().getJwtToken();
      const refreshToken = result.getRefreshToken().getToken();

      // Save the session data into session storage
      sessionStorage.setItem("idToken", idToken);
      sessionStorage.setItem("accessToken", accessToken);
      sessionStorage.setItem("refreshToken", refreshToken)
      setUser(accessToken)
      setSessions(cognitoUser)
      call_navigation()


      // Now, set up TOTP
      // confirmTOTP(cognitoUser,handleCloseTOTPModal);
    },
    onFailure: (err) => {

      if (err.message==="User is not confirmed."){
        handleOpenEmailModal()
        resendConfirmationCode(email, handleCloseEmailModal)
        
      }
      else{
      console.error('Failed to sign in after email verification:', err.message);
      toast.error('Incorrect username or password. Please try again, or consider signing up');
      }
    },
    mfaRequired: (codeDeliveryDetails) => {
      console.log("mfaRequired")
      // Handle MFA challenge, typically show a UI to enter the MFA code
    },
    mfaSetup: (challengeName, challengeParameters) => {
      console.log("here is mfaSetup")
      
      setupTOTP(cognitoUser,handleOpenTOTPModal,setQrCodeData);
    },

    totpRequired: (secretCode) => {

      // Here you should display an input for the user to enter the TOTP code
      // Assuming you have a way to collect this code, then:

    setIsTotpModalOpen(true);

      // Verify the TOTP code with Cognito

    }
  });
}

const call_navigation = () =>{
  navigate("/pages/projects/")
}

const signUp = ((email,password,firstName,lastName,handleOpenEmailModal)=> {
  setEmail(email)
  setPassword(password)

      
  const attributeList = [];
  const emailAttribute = {
      Name: 'email',
      Value: email
  };
  const givenNameAttribut = {
    Name: 'given_name',
    Value: firstName
  }
  const familyNameAttribute = {
    Name: 'family_name',
    Value: lastName
  }
  const userData = {
    Username: email, // Username here is the email used as identifier
    Pool: userpool, // Ensure userPool is correctly initialized elsewhere in your code
  };
  const cognitoUser = new CognitoUser(userData);
  setCognitoUser(cognitoUser)

  attributeList.push(new CognitoUserAttribute(emailAttribute));
  attributeList.push(new CognitoUserAttribute(givenNameAttribut));
  attributeList.push(new CognitoUserAttribute(familyNameAttribute));

  userpool.signUp(email, password, attributeList, null ,(err, result) => {
      if (err) {
        if (err.message ==="User already exists"){
          toast.error("Try Signing In or use a new Email", {
            position: "top-center",
            onClose: () => navigate('/pages/authentication/sign_in', { replace: true, state: { email: email } })})
          
        }
        else {
        toast.error(err.message || "An error occurred during registration. Try Signing In or use a new Email", {
          position: "top-center",
          onClose: () => navigate('/pages/authentication/sign_up', { replace: true, state: { email: email } })
        }); } 
      }
      else {

      // setUser(result.user); // Save the user for the TOTP step

      handleOpenEmailModal(); // Open the Email Verification Modal
 
      }
  });
});

    // Sign in after email verification to get the session
  const signInAfterVerification = (handleCloseEmailModal,handleOpenTOTPModal) => {

      const authenticationDetails = new AuthenticationDetails({
        Username: email,
        Password: password,
      });
    
      const cognitoUser = new CognitoUser({
        Username: email,
        Pool: userpool,
      });
     
      handleCloseEmailModal()
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (result) => {

           // Save the cognitoUser to use later for TOTP
          const idToken = result.getIdToken().getJwtToken();
          const accessToken = result.getAccessToken().getJwtToken();
          const refreshToken = result.getRefreshToken().getToken();
  
          // Save the session data into session storage
          sessionStorage.setItem("idToken", idToken);
          sessionStorage.setItem("accessToken", accessToken);
          sessionStorage.setItem("refreshToken", refreshToken)
          navigate('/pages/authentication/sign_in', { replace: true, state: { email: email } })
          
          
    
          // Now, set up TOTP
          // confirmTOTP(cognitoUser,handleCloseTOTPModal);
        },
        onFailure: (err) => {
          console.error('Failed to sign in after email verification:', err.message);
          toast.error('Failed to sign in. Please try again.');
        },
        mfaRequired: (codeDeliveryDetails) => {
          console.log("mfaRequired")
          // Handle MFA challenge, typically show a UI to enter the MFA code
        },
        mfaSetup: (challengeName, challengeParameters) => {
          
         
          setupTOTP(cognitoUser,handleOpenTOTPModal);
        },
      });
    };

    const verifyEmail = (handleCloseEmailModal,verificationCode,handleOpenTOTPModal) => {
  
      if (cognitoUser){
      cognitoUser.confirmRegistration(verificationCode, true, (err, result) => {
        if (err) {

          toast.error('Failed to verify email. Please try again.', err);
        } else {


            
            signInAfterVerification(handleCloseEmailModal,handleOpenTOTPModal);
 // Call setupTOTP after email verification
        }
      });
    }
    };

    const confirmTOTP = (totpCode, handleCloseTOTPModal) => {

            
      if (cognitoUser) {
       
        cognitoUser.verifySoftwareToken(totpCode, 'knowli-app', {
            onSuccess: (result) => {
              
              // Define MFA settings
              // const mfaOptions = {
              //   SoftwareTokenMfaSettings: {
              //     Enabled: true,
              //     PreferredMfa: true,
              //   },
              // };

              


                  // Save tokens in session storage

                  navigate('/pages/authentication/sign_in');
              },
            
            onFailure: (err) => {

              console.error("Failed to verify TOTP code:", err);
              toast.error('Failed to verify TOTP code. Please try again.');
            },
          });}

      else {
        toast.error('User information is missing. Please sign in again.');
      }
    };

const setupTOTP = (cognitoUser,handleOpenTOTPModal) => {
   
    
    setCognitoUser(cognitoUser)
    if (cognitoUser) {
      cognitoUser.associateSoftwareToken({
        associateSecretCode: (secretCode) => {
          
          const qrData = `otpauth://totp/knowli-app:${cognitoUser.getUsername()}?secret=${secretCode}&issuer=knowli-app`;
         
          // setQrCodeData(qrData)
           // Set the QR code data to display in the TOTP Modal
          
          callOpenTOTPmodal(qrData,handleOpenTOTPModal);
        },
        onFailure: (err) => {
    
          toast.error('Failed to set up TOTP. Please try again.');
        },
      });
    } else {
      toast.error('User information is missing. Please sign up again.');
    }
  };


  const callOpenTOTPmodal = (qrData,handleOpenTOTPModal)=>{
    
  
  
  handleOpenTOTPModal(qrData)
  }






  const setSessions = (cognitoUser) =>{
    cognitoUser.getUserAttributes((err, result) => {
      if (err) {
        console.error("Failed to get user attributes:", err);
        return;
      }   
      // Loop through the attributes and extract the desired ones
      result.forEach((attribute) => {
        sessionStorage.setItem(attribute.getName(), attribute.getValue())
  })
})}


  const TotpSubmit = (totpCode, landingPageUrl) =>{

    cognitoUser.sendMFACode(totpCode, {
      onSuccess: (result) => {
        const idToken = result.getIdToken().getJwtToken();
        const accessToken = result.getAccessToken().getJwtToken();
        const refreshToken = result.getRefreshToken().getToken();
        sessionStorage.setItem("idToken", idToken);
        sessionStorage.setItem("accessToken", accessToken);
        sessionStorage.setItem("refreshToken", refreshToken);
        setUser(accessToken)
        setSessions(cognitoUser)
      
        // Redirect or perform actions after successful sign-in
        navigate(landingPageUrl)
    },
      onFailure: (err) => {
        console.error("Failed to verify TOTP code:", err.message);
        toast.error(`Failed to verify TOTP code. Please try again.${err.message}`);
      },
    }, "SOFTWARE_TOKEN_MFA");
  }

  const reset = (newPassword, email, verificationCode, setOpenModal)=>{

    const cognitoUser = new CognitoUser({Username: email, Pool: userpool})
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onSuccess: () => {
        toast.success("Password reset successful.", {
          position: "top-center",
          
          onClose: () => navigate('/pages/authentication/sign_in', { replace: true })
      },)
      setOpenModal(false)
    },
      onFailure: (err) => {
        toast.error("Unable to reset passord.", {
          position: "top-center",
          onClose: () => navigate('/pages/authentication/sign_up', { replace: true })
      },)}
  });
}

const forgotPassword = (email, setOpenModal) =>{
  if (!email){
    alert("enter email")
    return
  }
    const username = email
    const userData = {
        Username: username,
        Pool: userpool
    };

    const cognitoUser = new CognitoUser(userData);

    cognitoUser.forgotPassword({
        onSuccess: function () {

        },
        onFailure: function(err) {
            console.error('Error sending reset password code:', err);
        },
        inputVerificationCode: function(data) {

            // The UI should ask the user to enter the verification code along with a new password.
        }
    });
    setOpenModal(true)
}

 const resendConfirmationCode = (email, handleCloseEmailModal) =>{
    const username = email
    const userData = {
        Username: username,
        Pool: userpool
    };

    const cognitoUser = new CognitoUser(userData);
    cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
            console.error("Error resending confirmation code:", err);
        } else {
            toast.error("Verification email resent. Please check your inbox.", {
              position: "top-center",
          
              //onClose: () => navigate('/pages/authentication/sign_in', { replace: true })
            });
            
        }
    });
  }


    

  
    


  const signOut = (signOutPageUrl) => {
    setUser(null);
    sessionStorage.removeItem("user");
    navigate(signOutPageUrl)
  };

  return (
    <AuthContext.Provider value={{ user, signIn, signUp, signOut , verifyEmail, TotpSubmit,confirmTOTP, reset, forgotPassword, resendConfirmationCode}}>
      {children}

    </AuthContext.Provider>
  );
};
