import React, { useState, useEffect, useRef} from 'react';


import { Box,TextField, Button,  Container} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import Modal from '@mui/material/Modal';
import MKBox from 'components/MKBox'
import { createTheme, ThemeProvider } from '@mui/material/styles';
import MessageCitationList from 'components/MessageList/messageCitationList'
import TypingSpinner from 'components/TypingSpinner';
// import Radio from '@mui/material/Radio';
// import RadioGroup from '@mui/material/RadioGroup';
// import FormControlLabel from '@mui/material/FormControlLabel';
// import FormControl from '@mui/material/FormControl';
// import FormLabel from '@mui/material/FormLabel';




import PDFViewer from 'components/PDFViewer';
// import { useNavigate } from 'react-router-dom';


const theme = createTheme({
  palette: {
    primary: {
      main: '#0C80A4',
    },
    secondary: {
      main: '#C45911',
    },
    background: {
      default: '#eeeeee',
    },
    text: {
      primary: '#FFFFFF',
    },
  },
  multilineColor:{
    color:'red'
},
  typography: {
    // fontFamily: 'Berlin Sans FB',
    h3: {
      color: '#FFFFFF',
    },
    h5: {
      color: '#FFFFFF',
    },
    body1: {
      color: '#FFFFFF',
    },
    h6: {
      color: '#FFFFFF',
    },
    body2: {
      color: '#FFFFFF',
    },
  },
});



const serverBaseURL = process.env.REACT_APP_BACKEND_URL;
//const serverBaseURL = "https://www.projectdelphi.ai"
//const serverBaseURL = "http://localhost:8000"

function ContractPage() {
  const [data, setData] = useState(null);
  const [input, setInput] = useState('');
  const [messages, setMessages] = useState([]);
  // const [thread_id_deloitte, setThread_id_deloitte]= useState(null)
  // const [thread_id_accenture, setThread_id_accenture]= useState(null)
  const [thread_id, setThread_id] = useState('');
  const [assistant_id, setAssistantId] = useState('');
  const [assistant_title, setAssistantTitle] = useState("");
  const [loading, setLoading]= useState(false)
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [pdfUrl, setPdfUrl] = useState('');
  const [highlights, setHighlights] = useState([]);
  const [handle_loading_message, setHandleLoadingMessage] = useState(false)
  const [coordinates, setCoordinates] = useState(null)
  const [startPage, setStartPage] = useState(null)
  const [isLoggedIn, setIsLoggedIn] = useState(true)
  // const [layoutToggle, setLayoutToggle] = useState(false);
  // const [radioValue, setRadioValue] = useState("deloitte")




  useEffect(() => {
    // If the user is logged in and has completed the form
    if (isLoggedIn) {
  
      handleInitialize(sessionStorage.getItem("given_name"), "accenture");
    }
  });

//   const handleToggleChange = (event) => {
//     setLayoutToggle(event.target.checked);
//     if (event.target.checked){
//       setAssistantId("accenture")
//       // if (thread_id_accenture) {
//       //   typeOut("Switching to Accenture Contract", "accenture");
//       // }
      
//       handleInitialize(sessionStorage.getItem("given_name"), "accenture")
//     }
//     else{
//       setAssistantId("deloitte")
//       // if (thread_id_deloitte){
//       //   typeOut("Switching to Deloitte Contract", "deloitte")
//       // }
//       handleInitialize(sessionStorage.getItem("given_name"), "accenture");
//     }
    
// };
  
  const handleInitialize = async (firstName, assistant_id)=>{
    setAssistantId(assistant_id)
    setIsLoggedIn(false)
    console.log("This is assistant_id ", assistant_id)
    // if (assistant_id === "deloitte") {
     
    //   setThread_id(thread_id_deloitte)
    //   setAssistantTitle("Florida Contract D")      
    // }
    // else {
    //   setThread_id(thread_id_accenture)
    //   setAssistantTitle("Florida Contract A")
    // }
    
    try {
      const response = await fetch(`${serverBaseURL}/policy-api/get-policy-thread`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ firstName:`${firstName}`, assistant_id:`${assistant_id}` }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      setLoading(false)
      
      const data = await response.json();
      // if (assistant_id === "deloitte") {
      //   console.log("")
   
      //   setThread_id_deloitte(data.thread_id)
      //   setAssistantTitle("Florida Contract D")      
      // }
      // else {

      //   setThread_id_accenture(data.thread_id)
      //   setAssistantTitle("Florida Contract A")
      // }
      
      setThread_id(data.thread_id)
      setHandleLoadingMessage(false)
      typeOut(data.message, assistant_id); 

    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
    }
  }

  const handleSend = () => {

    setHandleLoadingMessage(true)
    if (input.trim() !== '') {
      const newMessage = { user: 'You', text: input, time: new Date().toLocaleTimeString() };
      setMessages((prevMessages) => [...prevMessages, newMessage]);
      const query = input
      setInput('');
      handleResponse(query)
    }
  };

  const handleResponse = async (query) => {
    if (query ===""){

      return
    }
    try {
    
      const response = await fetch(`${serverBaseURL}/policy-api/continue-policy-thread`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ thread_id : thread_id, new_message: query, assistant_id: assistant_id }),
      });
  
      if (!response.ok) {
        throw new Error('Network response was not ok now');
      }
     
      const data = await response.json();
      setData(data)
      
      typeOut(data.message, assistant_id); 
    } catch (error) {
      console.error('There was a problem with the fetch operation:', error);
      const data = {"message": "This was an error with the server","citations":[]}
      setData(data)
      typeOut(data.message , assistant_id)
    }
  }

  const filterAndExtractSentences = (data, selectedFile) => {
    let sentences = [];

    const filteredItems = data.filter(item => item.file === selectedFile).map(item => ({
                    x0: item.x0,
                    y0: item.y0,
                    x1: item.x1,
                    y1: item.y1,
                    pageIndex: item.pageIndex
                }))
                .filter((item,index,self)=>
                index === self.findIndex((t) =>(
                  t.x0 === item.x0 && 
                  t.y0 === item.y0 && 
                  t.x1 === item.x1 && 
                  t.y1 === item.y1 && 
                  t.pageIndex === item.pageIndex
                )))

    let highestScorePage = null;

    if (filteredItems.length > 0) {
                  // Use reduce to find the item with the maximum score
                  const highestScoreItem = filteredItems.reduce((maxItem, currentItem) => {
                      return (currentItem.score > maxItem.score) ? currentItem : maxItem;
                  }, filteredItems[0]);
          
                  highestScorePage = highestScoreItem.pageIndex;
              }            
            
            // Concatenate the filtered sentences
    sentences = sentences.concat(filteredItems);
        
    
    return {
      sentences, 
      highestScorePage
    }
  }

  


  const handleOpen = async (citation) => {
    
    const filename = citation.file_name;
    try {
        // Initiate both fetch operations concurrently
        const pdfFetchPromise = fetch(`${serverBaseURL}/policy-api/get_citations`, {
            method: "POST",
            headers: {
                "Content-Type": "application/json",
            },
            body: JSON.stringify({filename: filename, assistant_id: assistant_id })
        });

        const pdfResponse = await pdfFetchPromise;

        if (!pdfResponse.ok) {
            throw new Error('Failed to fetch PDF.');
        }
        citation = data["results"]

        let needed_citation = filterAndExtractSentences(citation, filename)
        
        setCoordinates(needed_citation.sentences)
        setStartPage(needed_citation.highestScorePage)

        const blob = await pdfResponse.blob();
        const pdfUrl = URL.createObjectURL(blob);
        setPdfUrl(pdfUrl);

        if (pdfUrl) {
        
          openModal()
        }
    } catch (error) {
        console.error('Error fetching data:', error);
    }
};

  // const handleRadioChange = (event) => {
  //   setRadioValue(event.target.value);
  //   handleInitialize(sessionStorage.getItem("given_name"), event.target.value);
  // };



  const openModal = () =>{
    setModalIsOpen(true)
  }
  const closeModal = () => {
    setModalIsOpen(false);
    setHighlights(null)
    
  };

  const typeOut = (text_returned,assistant_id) => {
    setHandleLoadingMessage(false)
    let title;
    if (assistant_id === "deloitte") {
      title = "Florida Contract D";  
      setAssistantTitle(title)   
    }
    else {
      title = "Florida Contract A"
      setAssistantTitle(title)
    }
    const text = text_returned.message
    const citations = text_returned.citations
    setMessages((prevMessages) => {
      const lastMessage = prevMessages[prevMessages.length - 1];

      if (lastMessage && lastMessage.user === `${title} Contract AI Assistant`) {
        const updatedMessage = {
          ...lastMessage,
          text: lastMessage.text + text + (/[.!?]$/.test(text) ? ' ' : '')  // Add a space only if the text ends with a punctuation mark
        };
        return [...prevMessages.slice(0, -1), updatedMessage];
      } else { 
      
        let user_message = `${title} Contract AI Assistant`
        const newMessage = {
          user: user_message,
          text: text + (/[.!?]$/.test(text) ? ' ' : ''),  // Add a space only if the text ends with a punctuation mark
          time: new Date().toLocaleTimeString(),
          citations: citations
        };
        
        return [...prevMessages, newMessage];
      }
    });

  };
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  // Cleanup on component unmount
  useEffect(() => { 
    scrollToBottom();
  }, [messages]);

  useEffect(() => { 
    scrollToBottom()
  },[handle_loading_message]);


  return (
    
    <ThemeProvider theme={theme}>
      {loading ? (

        <MKBox sx={{textShadow: "1px 1px 2px #ccc" ,display: 'flex',  justifyContent: 'center', paddingTop:15,paddingBottom:14, backgroundColor: theme.palette.background.default}}>
       <TypingSpinner message={`Loading ${assistant_title} Contract. Please Wait`} />
       </MKBox>
      ):(
      <>
      <Box sx={{display: 'flex', flexDirection: 'column', height: '90vh', background: "linear-gradient(to right,#003366, #00AACC, #003366)", color: '#fff'}}>
      {/* <FormControl sx ={{marginLeft:"15px", marginTop:"15px"}}>
        <FormLabel id="demo-radio-buttons-group-label" sx={{color:"#fff"}}>Contract Type</FormLabel>
        <RadioGroup
          aria-labelledby="demo-radio-buttons-group-label"
          // defaultValue="deloitte"
          name="radio-buttons-group"
          value={radioValue}
          onChange={handleRadioChange}
        >
          <FormControlLabel value="deloitte" control={<Radio />} label="Contract D" />
          <FormControlLabel value="accenture" control={<Radio />} label="Contract A" />

        </RadioGroup>
      </FormControl> */}
      {/* <FormGroup sx={{color:"#fff"}}>
        <FormControlLabel
            control={
              <Switch
                checked={layoutToggle}
                onChange={handleToggleChange}
                sx={{
                  '& .MuiSwitch-track': {
                    backgroundColor: '#fff', // Override the track color
                  },
                  '& .MuiSwitch-thumb': {
                    color: '#fff', // Override the thumb color
                  },
                }}
              />
            }
            label={layoutToggle ? "Contract A" : "Contract D"}
            sx={{
              color: '#fff', // Override the label color
              '& .MuiTypography-root': { // Targets the Typography component within FormControlLabel
                color: '#fff', // Ensure the text color is white
              },
              float: "right",
              marginLeft: "10px",
              marginTop:"10px"
            }}

        />
    </FormGroup> */}
      <Modal
              open={modalIsOpen}
              onClose={closeModal}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
              sx={{ 'display':'flex',
                'justifyContent': 'center',
                'alignItems': 'center',
                'position':' fixed',
                'top': 0,
                'left': 0,
              'width': '100%',
                'height': '100%',
                'background': 'rgba(10, 0, 0, 0.5)',/* Semi-transparent background */
                'z-index': 1000 /* Ensure it's on top */}}
            >
              <MKBox  >
                <Container >
            <PDFViewer pdfUrl={pdfUrl} highlights={highlights} highlight_coordinates={coordinates} start_page={startPage} />
              </Container>
              </MKBox>
            </Modal>


    <MessageCitationList messages={messages} handleOpen={handleOpen} loading={handle_loading_message}/>

    <Box sx={{ display: 'flex', padding: 1,paddingBottom:5, backgroundColor: "theme.palette.background.default" }}>
      <TextField
        variant="outlined"
        placeholder="Type a message"
        fullWidth
        multiline
        inputProps={{ style: { color: "#ffffff" } }}
        minRows={2}
        value={input}
        onChange={(e) => setInput(e.target.value)}
        onKeyPress={(e) => { if (e.key === 'Enter' && !e.shiftKey) { handleSend(); e.preventDefault(); } }}
        sx={{
          
          '& .MuiInputBase-root': {
            color: '#ffffff',
          },
          '& .MuiOutlinedInput-notchedOutline': {
            borderColor: '#ffffff',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: '#ffffff',
          },
          '& .MuiOutlinedInput-root': {
            '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
              borderColor: '#ffffff',
            },
          },
          paddingLeft:20,
          paddingRight:10,
        }}
      />
      <Button variant="contained" onClick={handleSend}  sx={{
    marginLeft: 1,
    backgroundColor: '#00608d',
    '&:hover': {
      backgroundColor: '#00608d',
    },

  }}>
        <SendIcon />
      </Button>
    </Box>
    </Box>
</>)}

  </ThemeProvider>

)}

export default ContractPage;
