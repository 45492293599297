/**
=========================================================
* Material Kit 2 PRO React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-pro-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { Link} from "react-router-dom";
import { useState, useEffect } from "react";

// @mui material components
import Card from "@mui/material/Card";
import { List, ListItem, ListItemText } from "@mui/material";

// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";
import MKButton from "components/MKButton";
import { Modal, TextField } from '@mui/material';

import {QRCodeSVG} from 'qrcode.react';


import { ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


// Authentication layout components
import CoverLayout from "pages/authentication/components/CoverLayout";
import { useAuth } from "pages/authentication/AuthContext";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";


function SignUpCover() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [openEmailModal, setOpenEmailModal] = useState(false); // Modal for Email Verification
  const [openTOTPModal, setOpenTOTPModal] = useState(false);
  const [qrCodeString, setQrCodeString] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [totpCode, setTotpCode] = useState('');
  const [qrCodeData, setQrCodeData] = useState('');

  const {signUp, verifyEmail, confirmTOTP, resendConfirmationCode} = useAuth()

 
  const handleOpenEmailModal = () => setOpenEmailModal(true);
  const handleCloseEmailModal = () => setOpenEmailModal(false);

  const handleCloseTOTPModal = () => setOpenTOTPModal(false);

  
  // const [email, setEmail] = useState('');
  const handleOpenTOTPModal = (qrcode) => {
    console.log(qrcode)
    setQrCodeData(qrcode)
    const urlParams = new URLSearchParams(qrcode.split('?')[1]);
    const secret = urlParams.get('secret');
    console.log(secret)
    setQrCodeString(secret)
    setOpenTOTPModal(true);
  }

  const onSubmit = event => {
        event.preventDefault();
        if (!passwordsMatch) {
          alert("Passwords do not match!");
          return;
      }
      signUp(email, password, firstName, lastName, handleOpenEmailModal)
    }
      
//         const attributeList = [];
//         const emailAttribute = {
//             Name: 'email',
//             Value: email
//         };
//         const givenNameAttribut = {
//           Name: 'given_name',
//           Value: firstName
//         }
//         const familyNameAttribute = {
//           Name: 'family_name',
//           Value: lastName
//         }

//         attributeList.push(new CognitoUserAttribute(emailAttribute));
//         attributeList.push(new CognitoUserAttribute(givenNameAttribut));
//         attributeList.push(new CognitoUserAttribute(familyNameAttribute));

//         userpool.signUp(email, password, attributeList, null ,(err, result) => {
//             if (err) {
//               if (err.message ==="User already exists"){
//                 toast.error("Try Signing In or use a new Email", {
//                   position: "top-center",
//                   onClose: () => navigate('/pages/authentication/sign_in', { replace: true, state: { email: email } })})
                
//               }
//               else {
//               toast.error(err.message || "An error occurred during registration. Try Signing In or use a new Email", {
//                 position: "top-center",
//                 onClose: () => navigate('/pages/authentication/sign_up', { replace: true, state: { email: email } })
//               }); } 
//             }
//             else {

//             setUser(result.user); // Save the user for the TOTP step

//             handleOpenEmailModal(); // Open the Email Verification Modal
       
//             }
//         });
//     };
//     const verifyEmail = () => {
  
//       if (user){
//       user.confirmRegistration(verificationCode, true, (err, result) => {
//         if (err) {

//           toast.error('Failed to verify email. Please try again.', err);
//         } else {


//             handleCloseEmailModal();
//             signInAfterVerification();
//  // Call setupTOTP after email verification
//         }
//       });
//     }
//     };

//     // Sign in after email verification to get the session
//     const signInAfterVerification = () => {

//         const authenticationDetails = new AuthenticationDetails({
//           Username: email,
//           Password: password,
//         });
      
//         const cognitoUser = new CognitoUser({
//           Username: email,
//           Pool: userpool,
//         });
      
//         cognitoUser.authenticateUser(authenticationDetails, {
//           onSuccess: (result) => {

//             setUser(cognitoUser); // Save the cognitoUser to use later for TOTP
//             const idToken = result.getIdToken().getJwtToken();
//             const accessToken = result.getAccessToken().getJwtToken();
//             const refreshToken = result.getRefreshToken().getToken();
    
//             // Save the session data into session storage
//             sessionStorage.setItem("idToken", idToken);
//             sessionStorage.setItem("accessToken", accessToken);
//             sessionStorage.setItem("refreshToken", refreshToken)
            
      
//             // Now, set up TOTP
//             // confirmTOTP(cognitoUser);
//           },
//           onFailure: (err) => {
//             console.error('Failed to sign in after email verification:', err.message);
//             toast.error('Failed to sign in. Please try again.');
//           },
//           mfaRequired: (codeDeliveryDetails) => {
            
//             // Handle MFA challenge, typically show a UI to enter the MFA code
//           },
//           mfaSetup: (challengeName, challengeParameters) => {
          
//             setUser(cognitoUser);
//             setupTOTP(cognitoUser);
//           },
//         });
//       };
      
//       const confirmTOTP = () => {

        
//         if (user) {
//             user.verifySoftwareToken(totpCode, 'knowli-app', {
//               onSuccess: (result) => {
                
      
//                 // Define MFA settings
//                 // const mfaOptions = {
//                 //   SoftwareTokenMfaSettings: {
//                 //     Enabled: true,
//                 //     PreferredMfa: true,
//                 //   },
//                 // };
    
//                 handleCloseTOTPModal();


//                     // Save tokens in session storage
//                     const idToken = result.getIdToken().getJwtToken();
//                     const accessToken = result.getAccessToken().getJwtToken();
//                     const refreshToken = result.getRefreshToken().getToken();
//                     sessionStorage.setItem("idToken", idToken);
//                     sessionStorage.setItem("accessToken", accessToken);
//                     sessionStorage.setItem("refreshToken", refreshToken)
//                     navigate('/pages/authentication/sign_in');
//                 },
              
//               onFailure: (err) => {
  
//                 console.error("Failed to verify TOTP code:", err);
//                 toast.error('Failed to verify TOTP code. Please try again.');
//               },
//             });}

//         else {
//           toast.error('User information is missing. Please sign in again.');
//         }
//       };

//     const setupTOTP = (cognitoUser) => {
      
//         setUser(cognitoUser)
      
//         if (cognitoUser) {
//           cognitoUser.associateSoftwareToken({
//             associateSecretCode: (secretCode) => {
              
//               const qrData = `otpauth://totp/knowli-app:${cognitoUser.getUsername()}?secret=${secretCode}&issuer=knowli-app`;
//               setQrCodeData(qrData); // Set the QR code data to display in the TOTP Modal
//               callOpenTOTPmodal(qrData);
//             },
//             onFailure: (err) => {
        
//               toast.error('Failed to set up TOTP. Please try again.');
//             },
//           });
//         } else {
//           toast.error('User information is missing. Please sign up again.');
//         }
//       };
      
  
//     const callOpenTOTPmodal = (qrData)=>{

//       setQrCodeData(qrData)
//       const urlParams = new URLSearchParams(qrData.split('?')[1]);
//       const secret = urlParams.get('secret');
//       setQrCodeString(secret)
//       handleOpenTOTPModal()
//     }

  const handleResendVerification = () =>{
    resendConfirmationCode(email)
  }
   const handleVerifyEmail = () =>{
      console.log(verificationCode)
      if (!verificationCode) {
        alert("Enter Verification Code!");
        return;
  }
    verifyEmail(handleCloseEmailModal, verificationCode,handleOpenTOTPModal)
   }
   
   const handleConfirmTOTP =() =>{
    if (!totpCode){
      alert("Enter Verification Code !")
      return
    }
    confirmTOTP(totpCode, handleCloseTOTPModal)
   }


  
    useEffect(() => {
      // Check if passwords match whenever password or confirmPassword changes
      setPasswordsMatch(password === confirmPassword);
  }, [password, confirmPassword]);



  return (
    <CoverLayout image={bgImage} >
      <ToastContainer position="top-center" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
      <Card>
        <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Join us today
          </MKTypography>
          <MKTypography display="block" variant="button" color="white" my={1}>
            Enter your email and password to register
          </MKTypography>
        </MKBox>
        <MKBox p={3}>
          <MKBox component="form" role="form">
          <MKBox mb={2}>
              <MKInput type="text" label="First Name" fullWidth onChange={(e)=>{setFirstName(e.target.value)}}/>
            </MKBox>
            <MKBox mb={2}>
              <MKInput type="text" label="Last Name" fullWidth onChange={(e)=>{setLastName(e.target.value)}}/>
            </MKBox>
           

            <MKBox mb={2}>
              <MKInput type="email" label="Email" fullWidth onChange={(e)=>{setEmail(e.target.value)}}/>
            </MKBox>
            <MKBox mb={.5}>
              <MKInput type="password" label="Password" fullWidth onChange={(e)=>{setPassword(e.target.value)}}/>
            </MKBox>
            <MKBox mb={.5}>
            <List>
        <ListItem >
          <ListItemText primary="Minimum password length: 8." primaryTypographyProps={{ sx: { fontSize: "0.65rem" } }}/>
        </ListItem>
        <ListItem>
          <ListItemText primary="Must include at least one uppercase letter." primaryTypographyProps={{ sx: { fontSize: "0.65rem" } }}/>
        </ListItem>
        <ListItem>
          <ListItemText primary="Must include at least one lowercase letter." primaryTypographyProps={{ sx: { fontSize: "0.65rem" } }} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Must include at least one number." primaryTypographyProps={{ sx: { fontSize: "0.65rem" } }}/>
        </ListItem>
        <ListItem>
          <ListItemText primary={
            <>
              Must include at least one special character: 
            </>
          } primaryTypographyProps={{ sx: { fontSize: "0.65rem" } }} />
        </ListItem>
        </List>
            
            </MKBox>
            <MKBox mb={2}>
              <MKInput
                type="password"
                label="Confirm Password"
                fullWidth
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
            </MKBox>
            {!passwordsMatch && confirmPassword && (
                    <MKBox style={{ color: 'red' }}>
                        Passwords do not match!
                    </MKBox>
                )}
            <MKBox mt={3} mb={1}>
              <MKButton variant="gradient" color="info" fullWidth onClick={onSubmit}>
                sign up
              </MKButton>
            </MKBox>
            <MKBox mt={3} mb={1} textAlign="center">
              <MKTypography variant="button" color="text">
                Already have an account?{" "}
                <MKTypography
                  component={Link}
                  to="/pages/Authentication/sign_in"
                  variant="button"
                  color="info"
                  fontWeight="medium"
                  textGradient
                >
                  Sign In
                </MKTypography>
              </MKTypography>
            </MKBox>
          </MKBox>
        
 
      {/* Email Verification Modal */}
      <Modal open={openEmailModal} onClose={handleCloseEmailModal} style={{bgColor:'white', width:'400px', margin:'auto',marginTop:'50px'}}>
      <Card sx={{paddingBottom:"15px"}}>
      <MKBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MKTypography variant="h3" fontWeight="medium" color="white" mt={1}>
            Verify Email
          </MKTypography>
          <MKTypography display="block" variant="button" color="white" my={1}>
            You will receive an e-mail in maximum 60 seconds
          </MKTypography>
        </MKBox>
          <TextField
            label="Verification Code"
            
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            sx={{marginTop:"10px",marginLeft:"10px", marginRight:"10px"}}
          />
          <MKButton variant="contained" color="info" onClick={handleVerifyEmail} sx={{display: 'flex', justifyContent:'center', margin: 'auto', mt:2}}>
            Verify Email
          </MKButton>
          <MKButton variant="contained" color="info" onClick={handleResendVerification} sx={{display: 'flex', justifyContent:'center', margin: 'auto', mt:2}}>
            Resend Code
          </MKButton>
      
        
       </Card>
      </Modal>

      <Modal open={openTOTPModal} onClose={handleCloseTOTPModal} style={{bgColor:'white', width:'400px', margin:'auto',marginTop:'50px'}}>
        <Card sx={{paddingBottom:"15px"}}>
        <MKBox          
        variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center">

          <MKTypography color="white">Scan this QR code with your authenticator app </MKTypography>
          <MKTypography color='white' sx={{ fontSize: "0.9rem", margin:"15px"  }}>
            Or enter your secret code : {qrCodeString}
          </MKTypography>
          </MKBox>
  
          <MKBox
          sx={{margin:'auto'}}>
          {qrCodeData && <QRCodeSVG 
          value={qrCodeData} 
          bgColor="#ffffff"  // Set background to white
          fgColor="#000000"  // Set foreground to black (QR code color)
          size={100}         // You can adjust the size if necessary

          />}
          </MKBox>
          <TextField
            label="Enter the code from your app"
            sx={{marginTop:"10px",marginLeft:"10px", marginRight:"10px"}}
            value={totpCode}
            onChange={(e) => setTotpCode(e.target.value)}
          />

          <MKButton variant="contained" color="info" onClick={handleConfirmTOTP} sx={{display: 'flex', justifyContent:'center', margin: 'auto', mt:2}}>
            Verify Code
          </MKButton>
        
        </Card>
      </Modal>


      </MKBox>
      </Card>
    </CoverLayout>
  );
}

export default SignUpCover;
