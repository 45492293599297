import { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";

// typed-js
import Typed from "typed.js";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import { AppBar, 
  Toolbar, 
  Button, 
  Box,
  Card,
  CardActionArea,
  IconButton, 
  Drawer, 

  ListItem, 
  ListItemText,
  ListItemButton,
  ListItemIcon} from '@mui/material';
import styled from '@mui/material/styles/styled';


// Material Kit 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
import MKTypography from "components/MKTypography";
import MenuIcon from '@mui/icons-material/Menu';
import {  mdiAccountGroup,mdiLaptopAccount,mdiMonitorDashboard,} from '@mdi/js';
import Icon from '@mdi/react';



// Routes
// import routes from "routes";

// Images
import bgImage from "assets/images/IMG_7645.GIF"
import smImage from "assets/images/IMG_0158.gif"

const Jumbotron = styled(Box)(() => ({
    background: 'linear-gradient(to right, #0c7bad, #0c79ad, #00AACC,#0979ac,#0979ac)',
    height: "70px",
    
    
  }));
  const StyledIcon = styled(Icon)({
    color: '#005a87',
  });


function DefaultHeader({ string_list, scrollToSection }) {
    const el = useRef(null);
    const [mobileOpen, setMobileOpen] = useState(false);
    
    const handleDrawerToggle = () => {
      setMobileOpen(!mobileOpen);
    };
  
    const drawer = (
      <Box
        sx={{ width: 250 }}
        role="presentation"
        onClick={handleDrawerToggle}
        onKeyDown={handleDrawerToggle}
      >

      <ListItem key="Projects" disablePadding sx={{ display: 'block'}}>
            <Card
            sx={{
              fontSize: "5px",
              margin: '10px 0',
              boxShadow: 3,
              borderRadius: 2,
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
          > <CardActionArea component={Link} to={`/pages/projects`}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                 
                  // justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr:  3 ,
                    justifyContent: 'center',
                  }}
                >
                 <StyledIcon path={mdiLaptopAccount} size={1} />
                </ListItemIcon>
                <ListItemText primary="Projects" primaryTypographyProps={{ fontSize: '18px' }} />
              </ListItemButton>
              </CardActionArea>
              </Card>
      </ListItem>
      <ListItem key="Dashboards" disablePadding sx={{ display: 'block'}}>
            <Card
            sx={{
              fontSize: "5px",
              margin: '10px 0',
              boxShadow: 3,
              borderRadius: 2,
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
          > <CardActionArea onClick={scrollToSection}>
              <ListItemButton
                sx={{
                  minHeight: 48,
                 
                  // justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 3,
                    // mr: open ? 3 : 'auto',
                    justifyContent: 'center',
                  }}
                >
                 <StyledIcon path={mdiMonitorDashboard} size={1} />
                </ListItemIcon>
                <ListItemText primary="Dashboards" primaryTypographyProps={{ fontSize: '18px' }} />
              </ListItemButton>
              </CardActionArea>
              </Card>
      </ListItem>
      <ListItem key="Our Team" disablePadding sx={{ display: 'block'}}>
            <Card
            sx={{
              fontSize: "5px",
              margin: '10px 0',
              boxShadow: 3,
              borderRadius: 2,
              transition: 'transform 0.3s ease-in-out',
              '&:hover': {
                transform: 'scale(1.05)',
              },
            }}
          > <CardActionArea component={Link} to="/pages/bio">
              <ListItemButton
                sx={{
                  minHeight: 48,
                 
                  // justifyContent: open ? 'initial' : 'center',
                  px: 2.5,
                }}
              >
                <ListItemIcon
                  sx={{
                    minWidth: 0,
                    mr: 3,
                    justifyContent: 'center',
                  }}
                >
                 <StyledIcon path={ mdiAccountGroup} size={1} />
                </ListItemIcon>
                <ListItemText primary="Our Team" primaryTypographyProps={{ fontSize: '18px' }} />
              </ListItemButton>
              </CardActionArea>
              </Card>
      </ListItem>

      </Box>
    );
    

    // Setting up typedJS
    useEffect(() => {
        const strings = string_list;
        
        const typed = new Typed(el.current, {
            strings: strings,
            typeSpeed: 100,
            backSpeed: 50,
            backDelay: 2500,
            startDelay: 1000,
            loop: true,
        });

        return () => typed.destroy();
    }, [string_list]);


    return (
        <MKBox component="header" position="relative">

    <MKBox component="nav" position="fixed" width="100%" sx={{ zIndex: 1000 }}>
    <Jumbotron> 
      <AppBar position="fixed" sx={{ zIndex: 1000 }}>
        <Toolbar style={{ justifyContent: 'space-between', marginLeft: '0', marginRight: '2rem' }}>
          <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <img src={smImage} alt="Logo" style={{ height: '50px', marginRight: '1rem' }} />
            <MKTypography variant="h6" component="div" color="white">
              Knowli-AI
            </MKTypography>
          </Box>

          {/* Desktop Menu */}
          <Box sx={{ display: { xs: 'none', md: 'flex' }, flexGrow: 1, justifyContent: 'center' }}>
            <Button style={{ color: 'white' }} component={Link} to="/pages/projects">
              Policy Chat
            </Button>
            <Button style={{ color: 'white' }} onClick={scrollToSection}>
              Dashboards
            </Button>
            <Button style={{ color: 'white' }} component={Link} to="/pages/bio">
              Our Team
            </Button>
          </Box>

          {/* Mobile Menu - Hamburger Icon */}
          <Button style={{ color: 'white' }} component={Link} to="/pages/authentication/sign_in">
            Sign In
          </Button>
          <Box sx={{ display: { xs: 'block', md: 'none' },float:"right"  }}>
            <IconButton
              color="white"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{float:"right", color:"white"}}
            >
              <MenuIcon />
            </IconButton>
          </Box>
        </Toolbar>
      </AppBar>

      {/* Drawer for Mobile Menu */}
      <Drawer
        anchor="left"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{ keepMounted: true }} // Better open performance on mobile
      >
        {drawer}
      </Drawer>
      </Jumbotron> 
    </MKBox>
      
            <MKBox
            display="flex"
            alignItems="center"
            sx={{
            position: 'relative',
            display: 'inline-block',
            overflow: 'hidden',
            width: '100%', /* Make sure the feathered edge container takes full width of the container */
            minHeight:"70vh",
            background: '#021727',
            marginBottom: "100px"

            }}>
            <MKBox
                minHeight="40vh"
                sx={{
                    backgroundImage: () => `url(${bgImage})`,
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    maskImage: 'linear-gradient(to bottom, transparent, #011422 0%, #041927 25%, transparent)',
                    webkitMaskImage: 'linear-gradient(to bottom, transparent, #011422 0%, #041927 25%, transparent)',
                    maskComposite: 'intersect',
                    webkitMaskComposite: 'intersect'
                }}
            ></MKBox>
                <Container sx={{marginTop:"-100px"}} >
                    <Grid
                        container
                        item
                        xs={12}
                        lg={8}
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        textAlign="center"
                        mx="auto"
                        zIndex={1000}
                    >
                        <MKTypography
                            variant="h2"
                            color="white"
                            sx={({ breakpoints, typography: { size } }) => ({
                                [breakpoints.down("md")]: {
                                    fontSize: size["3xl"],
                                },
                            })}
                        >
                            Our mission is to <span ref={el} />
                        </MKTypography>
                        <MKTypography variant="body1" color="white" mt={1} mb={6} px={{ xs: 3, lg: 6 }}>
                            AI doesn't belong to the future, it dominates the present: Let's collaboratively craft your business's success story.
                        </MKTypography>
                        <Link to="/pages/Authentication/sign_in" >
                        <MKButton color="white" >Sign In To Explore</MKButton>
                        </Link>
                    </Grid>
                </Container>
            </MKBox>
        </MKBox>

    );
}

export default DefaultHeader;